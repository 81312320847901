import { Routes } from '@angular/router';

// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  ///////////////////////////////// OIT //////////////////////
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule),
    data: {
      title: 'full Views',
      allowedRoles: ['admin', 'director', 'editor', 'operator', 'user']
    }
  },
  {
    path: 'tasks',
    loadChildren: () => import('../../orthointouch/tasks/tasks.module').then(m => m.TasksModule),
    data: {
      title: 'full Views',
      allowedRoles: ['admin', 'director', 'editor', 'operator']
    }
  },
  {
    path: 'templates',
    loadChildren: () => import('../../orthointouch/templates/templates.module').then(m => m.TemplatesModule),
    data: {
      title: 'full Views',
      allowedRoles: ['admin', 'director', 'editor', 'operator']
    }
  },
  {
    path: 'notifications',
    loadChildren: () => import('../../orthointouch/home/notifications.module').then(m => m.NotificationsModule),
    data: {
      title: 'full Views',
      allowedRoles: ['admin', 'director', 'editor', 'operator', 'user']
    }
  },
  {
    path: 'paymentsupport',
    loadChildren: () => import('../../orthointouch/paymentsupport/paymentsupport.module').then(m => m.PaymetSupportModule),
    data: {
      title: 'full Views',
      allowedRoles: ['superadmin', 'paymentsupport', 'smiledoctors']
    }
  },
  {
    path: 'settings',
    loadChildren: () => import('../../orthointouch/settings/settings.module').then(m => m.SettingsModule),
    data: {
      title: 'full Views',
      allowedRoles: ['admin', 'director', 'editor', 'operator']
    }

  },
  {
    path: 'chat',
    loadChildren: () => import('../../chat/chat.module').then(m => m.ChatModule),
    data: {
      title: 'full Views',
      allowedRoles: ['chat', 'user']
    }
  },
  {
    path: 'sendgrid',
    loadChildren: () => import('../../orthointouch/sendgrid/sendgrid.module').then(m => m.SendgridModule),
    data: {
      title: 'full Views',
      allowedRoles: ['chat']
    }
  },
  {
    path: 'account',
    loadChildren: () => import('../../orthointouch/account/account.module').then(m => m.AccountModule),
    data: {
      title: 'full Views',
      allowedRoles: ['admin', 'director', 'editor', 'operator']
    }
  },
  {
    path: 'support',
    loadChildren: './admin/user/user.module#UserModule',
    data: {
      title: 'full Views',
      allowedRoles: ['admin', 'director', 'editor', 'operator']
    }
  },
  {
    path: 'users',
    loadChildren: () => import('../../orthointouch/membership/users/users.module').then(m => m.UsersModule),
    data: {
      title: 'full Views',
      allowedRoles: ['superadmin']
    }
  },
  {
    path: 'membership/users',
    loadChildren: () => import('../../orthointouch/membership/users/users.module').then(m => m.UsersModule),
    data: {
      title: 'full Views',
      allowedRoles: ['superadmin', 'admin']
    }
  },
  {
    path: 'membership/roles',
    loadChildren: () => import('../../orthointouch/membership/roles/roles.module').then(m => m.RolesModule),
    data: {
      title: 'full Views',
      allowedRoles: ['superadmin']
    }
  },
  {
    path: 'membership',
    loadChildren: () => import('../../orthointouch/membership/dashboard/dashboard.module').then(m => m.DashboardModule),
    data: {
      title: 'full Views',
      allowedRoles: ['superadmin']
    }
  },
  {
    path: 'admintasks',
    loadChildren: () => import('../../orthointouch/admin/admintasks/admintasks.module').then(m => m.AdminTasksModule),
    data: {
      title: 'full Views',
      allowedRoles: ['superadmin']
    }
  },
  {
    path: 'oitconfig',
    loadChildren: () => import('../../orthointouch/admin/oitconfig/oitconfig.module').then(m => m.OitconfigModule),
    data: {
      title: 'full Views',
      allowedRoles: ['superadmin']
    }
  },
  {
    path: 'sdcopytemplates',
    loadChildren: () => import('../../orthointouch/admin/sdcopytemplates/sdcopytemplates.module').then(m => m.SdCopyTemplatesModule),
    data: {
      title: 'full Views',
      allowedRoles: ['superadmin', 'smiledoctors']
    }
  },
  {
    path: 'emailblast',
    loadChildren: () => import('../../orthointouch/email-blast/email-blast.module').then(m => m.EmailBlastModule),
    data: {
      title: 'full Views',
      allowedRoles: ['superadmin', 'emailblasts']
    }
  },
  {
    path: 'patients',
    loadChildren: () => import('../../orthointouch/patient/patient.module').then(m => m.PatientModule),
    data: {
      title: 'full Views',
      allowedRoles: ['admin', 'director', 'editor', 'operator']
    }
  }
];
