// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {

  production: false,

  // Local Test
    //spaUrl : 'http://localhost:4200',
    //apiUrl : 'https://localhost:5001',
   // spaUrl : 'http://hjorthodontics.orthointouch.com/4200',

  // Staging
  // spaUrl : 'https://oitlegacy-staging-spa.azurewebsites.net',
  // apiUrl : 'https://oitlegacy-staging-api.azurewebsites.net',

  // Production
  spaUrl : 'https://console.orthointouch.com',
  apiUrl : 'https://api.orthointouch.com',

  // SD Config
  config: {

    tenant: 'a44a60a6-b8e3-4275-afd1-9233c741b3a8', // SD TenantID
    clientId: '104c26c2-8a03-4da3-96fe-ad95b6057e22', // ENTERPRISE: > '18f71db5-4524-46d6-a6f3-104833dab9c3', // SD OIT app (client) ID

    cacheLocation: 'localStorage', // enable this for IE, as sessionStorage does not work for localhost.

    redirectUri: window.location.origin + '/auth-callback',

    // Local Test
    // redirectUri: 'http://localhost:4200/auth-callback',
    // redirectUri: 'https://gulfcoast.orthointouch.com/auth-callback',

    // Staging
    // redirectUri: 'https://oitlegacy-staging-spa.azurewebsites.net/auth-callback',

    // Production
    // redirectUri: 'https://console.orthointouch.com/auth-callback',

    endpoints: {
      // Local Test
      // 'https://localhost:5001': '18f71db5-4524-46d6-a6f3-104833dab9c3'

      // Staging
      // 'https://oitlegacy-staging-api.azurewebsites.net': '18f71db5-4524-46d6-a6f3-104833dab9c3'

      // Production
       'https://api.orthointouch.com': '104c26c2-8a03-4da3-96fe-ad95b6057e22'

    },
  }

  // OIT Config
  // config: {
  //   tenant: 'c67165dc-3ff0-4f15-9766-561d4d10aa1b', // OrthoIntouch TenantID
  //   clientId: 'bbf01a89-e109-434b-b0cb-92293ef16b3f', // OrthoIntouch AD OIT app (client) ID

  //   cacheLocation: 'localStorage', // enable this for IE, as sessionStorage does not work for localhost.
  //   // Local Test
  //   redirectUri: window.location.origin + '/auth-callback',

  //   // Staging
  //   // redirectUri: 'https://oitlegacy-staging-spa.azurewebsites.net/auth-callback',

  //   // Production
  //   // redirectUri: 'https://console.orthointouch.com/auth-callback',

  //   endpoints: {
  //     // Local Test
  //     'https://localhost:5001': 'https://jordanorthointouch.onmicrosoft.com/00071ab2-8c29-44a6-9f19-a5e347ecd3ec'

  //     // Staging
  // tslint:disable-next-line:max-line-length
  //     // 'https://oitlegacy-staging-api.azurewebsites.net': 'https://jordanorthointouch.onmicrosoft.com/00071ab2-8c29-44a6-9f19-a5e347ecd3ec'

  //     // Production
  //     // 'https://api.orthointouch.com': 'https://jordanorthointouch.onmicrosoft.com/00071ab2-8c29-44a6-9f19-a5e347ecd3ec'

  //   },
  // }
};
