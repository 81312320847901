<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
  [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                  <div class="logo-img"><img class="logo-img" alt="" [src]="logoUrl"><span
                    class="text">ORTHOINTOUCH</span></div>
                </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <!-- <li class="dropdown nav-item" placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle dropdown-notification p-0 mt-2" id="drp-notification" href="javascript:;"
              data-toggle="dropdown" ngbDropdownToggle><i class="ft-bell font-medium-3" ></i><span
                class="notification badge badge-pill badge-danger"></span></a>
            <ul ngbDropdownMenu
              class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden">
              <li class="dropdown-menu-header">
                <div class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary">
                  <div class="d-flex">
                    <i class="ft-bell font-medium-3 d-flex align-items-center mr-2"></i><span class="noti-title">Notifications : </span>
                  </div>
                  <span class="text-bold-400 cursor-pointer" onclick="Notification.requestPermission().then(function(result) {
                     //var notification = new Notification('Hi there!');
                    console.log(result);
                 });">Enable Notifications</span>
                </div>
              </li>
              
              <li class="dropdown-menu-footer">
                <div class="noti-footer text-center cursor-pointer primary border-top text-bold-400 py-1">
                  <a class="dropdown-item" routerLink="/chat">
                    <div class="d-flex align-items-center">
                      <i class="ft-message-square mr-2"></i><span>Go to Chat</span>
                    </div>
                  </a>
              </div>
              </li>
            </ul>
          </li> -->
          <li class="dropdown nav-item mr-0" placement="bottom-left" display="static" ngbDropdown  [placement]="placement" >
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic3" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right">{{practicename}}</span><span
                  class="text-right text-muted font-small-3">{{username}}</span>
              </div>
              <div class="avatar mr-2 bg-info">
                <span class="avatar-content"><i class="ft-user font-medium-1"></i></span>
              </div>
              <p class="d-none">User Settings</p>
            </a>
            <div class="dropdown-menu text-left" style="left: auto; right: 0" aria-labelledby="dropdownBasic3">
              <a class="dropdown-item py-1" (click)="nav('/account/settings')">
                <i class="ft-settings mr-2"></i>
                <span>Settings</span>
              </a>
              <a class="dropdown-item py-1" (click)="nav('/pages/request-support')">
                <i class="ft-help-circle mr-2"></i>
                <span>Request Support</span>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()">
                <i class="ft-power mr-2"></i>
                <span>Logout</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
