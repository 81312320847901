import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError  } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService,
                private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      // // add authorization header with jwt token if available
      // const currentUser = this.authenticationService.currentUserValue;
      // if (currentUser && currentUser.token) {
      //     request = request.clone({
      //         setHeaders: {
      //             Authorization: `Bearer ${currentUser.token}`
      //         }
      //     });
      // }
      // return next.handle(request);

        // add authorization header with jwt token if available
        // const authUrl = `${environment.apiUrl}/token`;
        const tenantUrl = `${environment.apiUrl}/api/catalog/tenant_search`;

        const authUrl = `${environment.apiUrl}/api/account/authentication`;
        const forgotPwd = `${environment.apiUrl}/api/Account/ForgotPassword`;
        const resetPwd = `${environment.apiUrl}/api/Account/ResetPassword`;

        const numbersTollFreeUrl = `${environment.apiUrl}/api/AccountRegister/GetTollFreeSMSNumber`;
        const numbersByAreaUrl = `${environment.apiUrl}/api/AccountRegister/GetSMSNumberByAreaCode`;
        const verificationCodeUrl = `${environment.apiUrl}/api/AccountRegister/SendEmailValidation`;
        const verifyCode = `${environment.apiUrl}/api/AccountRegister/VerifyEmail`;
        const configTenant = `${environment.apiUrl}/api/AccountRegister/configuretenant`;

        const uploadUrl = `${environment.apiUrl}/blobs/upload`;
        const uploadCSVUrl = `${environment.apiUrl}/blobs/csvemailupload`;
        const uploadchatUrl = `${environment.apiUrl}/blobs/uploadchat`;


        // if (request.url !== 'http://localhost:52137/api/account/authentication')    {

        // Checking here to see if we need to authenticate via ADAL (Active Directore - SD / OIT)
        if (!request.url.includes(authUrl)
          && !request.url.includes(verificationCodeUrl)
          && !request.url.includes(tenantUrl)
          && !request.url.includes(numbersByAreaUrl)
          && !request.url.includes(configTenant)
          && !request.url.includes(numbersTollFreeUrl)
          && !request.url.includes(verifyCode)
          && !request.url.includes(forgotPwd)
          && !request.url.includes(resetPwd)
          && !request.url.startsWith('https://api.sendgrid.com'))    {

          let access_token = null;
          if (localStorage.getItem('adal.idtoken').indexOf('"') === 0) {
            access_token = JSON.parse(localStorage.getItem('adal.idtoken'));
          } else {
            access_token = JSON.parse('"' + localStorage.getItem('adal.idtoken') + '"');
          }

          if (access_token) {

            if (request.url === uploadUrl || request.url === uploadCSVUrl || request.url === uploadchatUrl) {
              request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${access_token}`
                }
              });
            } else {
              request = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${access_token}`,
                  'Content-Type': `application/json`
                }
              });
            }

            // .clone
            // ({
            //     setHeaders: {
            //       Authorization: `Bearer ${access_token}`,
            //     }
            //   });
          }

            // const currentUser = this.authenticationService.currentUserValue;
            // if (currentUser && currentUser.access_token) {
            //     request = request.clone({
            //         setHeaders: {
            //             Authorization: `Bearer ${currentUser.access_token}`
            //         }
            //     });
            // }
        } else {
            // var reqHeader = ;
            // reqHeader.append('Content', 'application/x-www-form-urlencoded');
            // request = request.clone({
            //      headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded'})
            // });
        }

        /// JG 8/25/2020 - Added when moving to core
        // const currentUser = this.authenticationService.getCurrentUser();
        // if (currentUser && currentUser.access_token) {
        //     request = request.clone({
        //         setHeaders: {
        //             Authorization: `Bearer ${currentUser.access_token}`
        //         }
        //     });
        // }
        // return next.handle(request);


        return next.handle(request).pipe(
          catchError((err: HttpErrorResponse) => {
            // debugger;
            if (err.status === 401) {
              // Handle 401 error
              this.router.navigate(['/login']);
            } else {
              return throwError(err);
            }
          })
        );
    }
}
