import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { Config } from 'app/shared/shared.config';
import { AuthService } from 'app/shared/auth/auth.service';
import { UserProfile, User } from 'app/shared/auth/user';
import { VerticalMenuService } from 'app/shared/vertical-menu/vertical-menu.service'

@Component({
  selector: 'app-auth-callback',
  templateUrl: './ad-auth-callback.component.html',
  styleUrls: ['./ad-auth-callback.component.css']
})
export class AuthCallbackComponent implements OnInit {

  instances;
  loginerror = '';
  selected_instance = '-1'; // set to default
  loginError = '';

  constructor(private router: Router,
              private adal: AdalService,
              private authService: AuthService,
              private sideBarService: VerticalMenuService,
              private _zone: NgZone) {

                this.instances = [];
               }

  ngOnInit() {

    this.adal.handleWindowCallback();
    // Retrieve a list of available instances
    this.authService.loadADInstances().subscribe(
      data => {
        if (data.length > 0) {
          this.instances = data;
        } else {
          this.loadProfile(-1);
        }
      }
      );



    // this.router.navigate(['/notifications/appointments']);
    // Need to get tenant ID assignements (should be apart of claim within token)
    // Need to display a list of valid tenants user has access to, once selected - they can connect to that tenant

    // setTimeout(() => {
    //   this._zone.run(
    //     () => this.router.navigate(['/'])
    //   );
    // }, 200);
  }

  loadProfile(id) {
     // Need to set userProfile from adal return obj
     this.authService.loadADSecurityContext(id)
     .subscribe((response: any) => {
       const user = response[Config.userProfile] as UserProfile;

       this.authService.setCurrentUser(user);
       localStorage.setItem(Config.userProfile, JSON.stringify(user));

       this.sideBarService.updateNav(user);
       this.sideBarService.updateLoginStatus(true);

       this.router.navigate(['paymentsupport/achnotifications']);
     });

  }

  onContinue() {
    if (this.selected_instance === '-1') {

      this.loginError = 'Instance selection required';
      return;
    } else {
      this.loginError = '';
      this.loadProfile(this.selected_instance);
    }

  }
  onLogout() {
    this.router.navigate(['login']);
  }

}
