<h2 mat-dialog-title>{{ data.viewTitle }}</h2>
<mat-dialog-content>
<p>{{ data.text }}</p>
<p><b>{{ data.message }}</b></p>
<mat-checkbox  ([ngModel])="data.deleteData" *ngIf="false" >Delete Database</mat-checkbox>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button class="mat-raised-button mat-primary" cdkFocusInitial (click)="save()">{{ data.confirmButtonText }}</button>
  <button mat-raised-button class="mat-raised-button" (click)="close()">{{ data.cancelButtonText }}</button>
</mat-dialog-actions>
