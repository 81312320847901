import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


import { BehaviorSubject, Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from './../../../environments/environment';

import { SetupState, User, UserProfile } from './user';
import { LoginModel } from './loginmodel';
import { RegisterModel } from './register.model';
import { ResponseModel } from './response.model';
import { Config } from '../shared.config';
import { AdalService } from 'adal-angular4';
import { HashLocationStrategy } from '@angular/common';
// import { debug } from 'console';

@Injectable()
export class AuthService {
  token: string;

  private userProfileSubject: BehaviorSubject<UserProfile>;
  private userSetupState: BehaviorSubject<number>;
  private numSetupState: number;

  constructor(private http: HttpClient, private adal: AdalService) {
    this.userProfileSubject = new BehaviorSubject<UserProfile>(JSON.parse(localStorage.getItem(Config.userProfile)));
    this.userSetupState = new BehaviorSubject<number>(JSON.parse(localStorage.getItem('setupState')));
    // this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    // this.currentUser = this.currentUserSubject.asObservable();
  }

  // public get currentUserValue(): User {
  //   return this.currentUserSubject.value;
  // }

  signupUser(registerModel: RegisterModel) {
    // your code for signing up the new user
    // your code for checking credentials and getting tokens for for signing in user
    return this.http.post<ResponseModel>(`${environment.apiUrl}/api/account/register`, registerModel)
    .pipe(map(response => {
        return response;
    }));
  }

  signinUser(email: string, password: string) {
    const loginModel = new LoginModel();
    loginModel.email = email;
    loginModel.password = password;

    // let tenantarg = '';
    // if (client_id !== '') {
    //   if (client_id.length > 0) {
    //     tenantarg = '?t_id=' + client_id;
    //   }
    // }

    // your code for checking credentials and getting tokens for for signing in user
    return this.http.post<any>(`${environment.apiUrl}/api/account/authentication`, loginModel)
    .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && (user.access_token || user.token)) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.token = (user.access_token) ? user.access_token : user.token;
            // debugger;
            localStorage.setItem(Config.accessToken, JSON.stringify(this.token));

            // localStorage.setItem('currentUser', JSON.stringify(user));
        }

        return user;
    }));
  }

  logout() {
    return this.http.post<ResponseModel>(`${environment.apiUrl}/api/account/logout`, null)
    .pipe(map(response => {
      localStorage.removeItem('currentUser');
      localStorage.removeItem(Config.userProfile);
      localStorage.removeItem(Config.accessToken);
      this.adal.clearCache();
      this.userProfileSubject.next(null);

      return response;
    }));
  }


  // getUserList() {
  //   return this.http.get<any>(`${environment.apiUrl}/api/account/getuserlist`)
  // }

  // getRoles() {
  //   // remove user from local storage to log user out
  //   return this.http.get<any>(`${environment.apiUrl}/account/getrolelist`);
  // }

  getToken() {
    return this.token;
  }

  isAuthenticated() {
    // here you can check if user is authenticated or not through his token
    return true;
  }


  /**
   * KW - 4/3/2019 - new method for retrieving user profile data after successful login.
   */
  loadSecurityContext() {
    if (!this.token) {
      this.token = localStorage.getItem(Config.accessToken);
    }
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('authorization', this.token);
    return this.http.get(`${environment.apiUrl}/api/account/authcontext`, { headers: requestHeaders });
  }

  /**
   * JG - 2/2022 - retrieve t id on login page
   */
   getTenantsSearch(email) {
    return this.http.get(environment.apiUrl + '/api/catalog/tenant_search/' + email);
  }

  /**
   * JG - 5/13/2019 - method for retrieving user profile data after successful login via AAD.
   */
  loadADSecurityContext(id) {
    if (!this.token) {
      this.token = localStorage.getItem(Config.accessToken);
    }
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('authorization', this.token);
    // return this.http.get(`${environment.apiUrl}/api/account/authadcontext/` + id + `?t_id=` + id, { headers: requestHeaders });
    return this.http.get(`${environment.apiUrl}/api/account/authadcontext/` + id, { headers: requestHeaders });
  }

  /**
   * JG - 5/13/2019 - method for retrieving user profile data after successful login via AAD.
   */
  loadADInstances(): Observable<any> {
    if (!this.token) {
      this.token = localStorage.getItem(Config.accessToken);
    }
     let requestHeaders = new HttpHeaders();
     requestHeaders = requestHeaders.set('Authorization', `Bearer ${this.token}`);

    return this.http.get(`${environment.apiUrl}/api/account/authadinstances`, { headers: requestHeaders });
    // return this.http.get(`${environment.apiUrl}/api/account/authadinstances`);
  }



  setCurrentUser(user: UserProfile) {
    this.userProfileSubject.next(user);
  }

  getCurrentUser() {
    return this.userProfileSubject.value;
  }


  /**
   * JG - 9/2022 - check to see if the setup requires DB/PMS or Phone config
   */
   setSetupState() {
    if (!this.token) {
      this.token = localStorage.getItem(Config.accessToken);
    }
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('authorization', this.token);
    return this.http.get(`${environment.apiUrl}/api/profile/setup/state`, { headers: requestHeaders });
  }

  getSetupState(): number {
    if (!this.numSetupState) {
      this.numSetupState = Number(localStorage.getItem('setupState'));
    }
    return this.numSetupState;
    //return this.userSetupState.value;
  }
}
