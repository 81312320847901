import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-confirmation-page',
    templateUrl: './confirmation-page.component.html',
    styleUrls: ['./confirmation-page.component.scss']
})

export class ConfirmationPageComponent implements OnInit {

    _activatedRoute: ActivatedRoute;
    w;

    constructor(private activatedRoute: ActivatedRoute,
                public router: Router,
                private sanitizer: DomSanitizer) {
                    this._activatedRoute = activatedRoute;
                    this.w = window;
                }


  ngOnInit(): void {

    let ret_url = '';
    this._activatedRoute.queryParams.subscribe(params => {
      if (params['retUrl']) {
        ret_url = params['retUrl'].trim();
      }
    });

    // Redirect to retUrl if exists
    if (ret_url.length > 0) {
        setTimeout(() => {
            // this.router.navigate([ret_url]);
            const prefix = 'http://';
            if (ret_url.substr(0, prefix.length) !== prefix) {
                ret_url = prefix + ret_url;
            }
            this.w.location.href = ret_url;
        }, 3000);
    }
  }
}
