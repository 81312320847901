import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct, NgbDateAdapter } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class CustomDateAdapter extends NgbDateAdapter<string> {
    readonly DELIMITER = '-';

    fromModel(value: string | null): NgbDateStruct | null {
        if (value) {
            let tokens = value.split(this.DELIMITER);
            return {
                day: parseInt(tokens[1]),
                month: parseInt(tokens[0]),
                year: parseInt(tokens[2])
            };
        }
        return null;
    }

    toModel(date: NgbDateStruct | null): string | null {
        return date ? date.month + this.DELIMITER + date.day + this.DELIMITER + date.year : null;
    }

}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

    readonly DELIMITER = '-';

    parse(value: string): NgbDateStruct | null {
        if (value) {
            let tokens = value.split(this.DELIMITER);
            return {
                day: parseInt(tokens[1]),
                month: parseInt(tokens[0]),
                year: parseInt(tokens[2])
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        return date ? date.month + this.DELIMITER + date.day + this.DELIMITER + date.year : '';
    }
}