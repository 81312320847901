import { ResponseBase } from '../../models/base';

export class ConfirmationDialogData {
  text = 'Are you sure you want to delete this?';
  viewTitle = 'Confirm Delete';
  message: string;
  cancelButtonText = 'No';
  confirmButtonText = 'Yes';
  deleteData: boolean;
  result: ResponseBase;
}
