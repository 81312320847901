import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChatMessage } from '../../chat/chat.model';

@Injectable()
export class ChatDataService {

    private chatMessageSource = new BehaviorSubject<ChatMessage>(null);
    currentchatMessage = this.chatMessageSource.asObservable()

    constructor() {}

    changeMessage(chatmessage: ChatMessage) {
        this.chatMessageSource.next(chatmessage);
    }
}
