import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AdalService } from 'adal-angular4';
import { AuthService } from 'app/shared/auth/auth.service';
import { UserProfile } from 'app/shared/auth/user';
import { Config } from 'app/shared/shared.config';
import { NgxSpinnerService } from 'ngx-spinner';
import { VerticalMenuService } from 'app/shared/vertical-menu/vertical-menu.service'
import { MessagingService } from 'app/shared/services/messaging.service';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})

export class LoginPageComponent implements OnInit {

  message;
  setupReq;
  url;
  count = 0;
  public t_ids = [];
  loginFormSubmitted = false;
  lookupFormSubmitted = false;
  isLoginFailed = false;
  loginError = '';
  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    // client_id: new FormControl('', [Validators.required]),
    rememberMe: new FormControl(true)
  });

  lookupForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
  });


  constructor(private router: Router, private authService: AuthService,
    private spinner: NgxSpinnerService,
    private sideBarService: VerticalMenuService,
    private adal: AdalService,
    private messagingService: MessagingService,
    private route: ActivatedRoute) {
  }



  ngOnInit(): void {
    this.spinner.hide();
    this.url = window.location.href;
    this.count = 0;
    const firstParam: string = this.route.snapshot.queryParamMap.get('email');
    this.loginForm.value.username = firstParam;
  }

  show_sso() {
    if (this.url.includes('gulfcoast.') ||
        this.url.includes('midatlantic.') ||
        this.url.includes('mideast.') ||
        this.url.includes('midwest.') ||
        this.url.includes('mountainwest.') ||
        this.url.includes('northtexas.') ||
        this.url.includes('southeast.') ||
        this.url.includes('southtexas.') ||
        this.url.includes('spillers.') ||
        this.url.includes('test.') ||
        this.url.includes('staging') ||
        this.url.includes('localhost')) {
          return true;
        } else {
          return false;
        }


  }

  // filterUpdate(e) {
  //   const searchTerm = e.target.value;
  //   if (this.validateEmail(searchTerm)) {
  //     this.authService.getTenantsSearch(searchTerm).subscribe(
  //       (data: any[]) => {
  //         this.t_ids = data;
  //       }
  //     )
  //   }
  //   if (this.t_ids.length > 0) {
  //     this.loginForm.patchValue({
  //       // redirect user to subdomain.orthointouch.com
  //       client_id: this.t_ids[0]
  //     });
  //   }
  // }

  validateEmail(email) {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    }

    // Used to lookup which URL they should be redirected
    onContinue() {
      this.lookupFormSubmitted = true;
      const email = this.lookupForm.value.email;
      if (this.validateEmail(email)) {
        this.authService.getTenantsSearch(email).subscribe(
          (data: any[]) => {
            this.t_ids = data;
          }
        ).add(() => {
          if (this.t_ids.length > 0) {
            // window.location.href = 'http://localhost:4200/login?email=' + email;
            window.location.href = 'https://' + this.t_ids[0].key +  '.orthointouch.com/login?email=' + email;
          }
        })
      }
    }

    get lf() {
      return this.loginForm.controls;
    }



    // On submit button click
    onSubmit() {
    this.loginFormSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    // this.authService.signinUser(this.loginForm.value.username, this.loginForm.value.password)
    //   .then((res) => {
    //     this.spinner.hide();
    //     this.router.navigate(['/dashboard/dashboard1']);
    //   })
    //   .catch((err) => {
    //     this.isLoginFailed = true;
    //     this.spinner.hide();
    //     console.log('error: ' + err)
    //   }
    //   );
    this.loginError = '';
    // if (typeof this.loginForm.value.client_id !== 'string') {
    //   this.loginForm.value.client_id = this.loginForm.value.client_id.key;
    // }

    // window.location.href = 'https://' + this.loginForm.value.client_id + '.orthointouch.com';

    this.authService
      .signinUser(this.loginForm.value.username, this.loginForm.value.password)
      .subscribe(
        items => {
          if (items.status === 'success') {
            // this._toastr.success(items.result, 'Success!' , {closeButton : true});
            this.authService.loadSecurityContext()
              .subscribe((response: any) => {
                const userProfile = response[Config.userProfile] as UserProfile;

                this.authService.setCurrentUser(userProfile);
                localStorage.setItem(Config.userProfile, JSON.stringify(userProfile));

                this.sideBarService.updateNav(userProfile);
                this.sideBarService.updateLoginStatus(true);

                // After User is logged in - Setup FCM Notifications
                this.messagingService.requestPermission();
                this.messagingService.receiveMessage();
                this.message = this.messagingService.currentMessage;

                // Check the setup state (has phone & PMS config'd)
                this.authService.setSetupState().subscribe((ret: any) => {
                  localStorage.setItem('setupState', JSON.stringify(ret));
                });

                // this.router.navigate(['/dashboard/dashboard1']);
                  this.router.navigate(['notifications/appointments']);

              });
          } else {
              // this._toastr.error(items.result, 'Error!', {closeButton : true});
              this.loginError = 'Incorrect username or password';
              this.isLoginFailed = true;
              this.spinner.hide();
          }
        },
        error => {
          // this._toastr.error('Incorrect username or password.','Login Failed!',{ timeOut: 2000 });
          this.loginError = 'Incorrect username or password';
          this.isLoginFailed = true;
          this.spinner.hide();
          console.log('error: ' + error);
          // console.log(error);
        }
      );


  }

  login() {
    // this.adal.config.clientId = '631ea943-1aa5-4742-9a1d-5e4b15cacb5a';
    this.adal.login();
  }

  isConsoleUrl() {
    // tslint:disable-next-line:max-line-length
    // if (this.url.includes('console.') || this.url.includes('oitlegacy-staging-spa.') || (this.url.includes('localhost') && this.url.includes('http://localhost:4200/login?email') === false)
    if (this.url.includes('console.') // || this.url.includes('oitlegacy-staging-spa.')
     ) {
        return true;
      } else {
        return false;
      }
    // return this.url.indexOf('console') > -1
}

}
