<!--Confirmation Starts-->
<section id="confirmation">
  <div class="container-fluid">
    <div class="row auth-height full-height-vh">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <div class="row">
          <div class="col-12 text-center">
            <img src="assets/img/gallery/login.png" alt="" class="img-fluid maintenance-img mt-2" height="300" width="300">
            <h1 class="mt-4">Confirmed!</h1>
            <div class="maintenance-text w-75 mx-auto mt-4">
              <p>Thank you for your response, we'll see you soon! </p>
            </div>
            <!-- <a [routerLink]="['/']" class="btn btn-warning my-2">Back To Home</a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Confimation Starts-->
