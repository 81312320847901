import { Component, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AdalService } from 'adal-angular4';
import { environment } from 'environments/environment';
import { MessagingService } from './shared/services/messaging.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

    subscription: Subscription;
    message;

    constructor(private router: Router,
        private adal: AdalService,
        private messagingService: MessagingService) {
        this.adal.init(environment.config);

    }

    ngOnInit() {
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => window.scrollTo(0, 0));

        // this.messagingService.requestPermission();
        // this.messagingService.receiveMessage();
        // this.message = this.messagingService.currentMessage;
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



}