import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { MembershipRoutingModule } from './membership-routing.module';
import { MembershipModalsModule } from './modals/membership-modals.module';

@NgModule({
  imports: [
    MembershipModalsModule,
    MembershipRoutingModule,
    FormsModule
  ],
  declarations: [],
  exports: [MembershipModalsModule]
})
export class MembershipModule { }
