import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from 'environments/environment';

@Component({
    selector: 'app-forgot-password-page',
    templateUrl: './forgot-password-page.component.html',
    styleUrls: ['./forgot-password-page.component.scss']
})

export class ForgotPasswordPageComponent {
    @ViewChild('f') forogtPasswordForm: NgForm;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private _httpClient: HttpClient) { }

    // On submit click, reset form fields
    onSubmit(myForm: NgForm) {
        const modelP = {
            // baseUrl: environment.spaUrl,
            baseUrl: window.location.origin,
            email: myForm.controls.inputEmail.value }

        this._httpClient.post(environment.apiUrl + '/api/Account/ForgotPassword', modelP).toPromise()
            .then(function (response) {
                return response;
            }).catch(function (error) {
                console.log('Error in getOITPlan' + error);
            });
        // this.forogtPasswordForm.reset();
        this.onLogin();
    }

    // On login link click
    onLogin() {
        this.router.navigate(['login'], { relativeTo: this.route.parent });
    }

    // On registration link click
    onRegister() {
        this.router.navigate(['register'], { relativeTo: this.route.parent });
    }
}
