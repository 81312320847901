import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { reject } from 'q';
import { environment } from 'environments/environment';
// import { UserRoutingModule } from 'app/admin/user/user-routing.module';

@Injectable()
export class RegisterService  {
    /**
     * Constructor
     * @param {HttpClient} _httpClient
     */
    constructor(
        private _httpClient: HttpClient
    ) {}

    sendregistrationcode(email, dbname, practice) {
        const payload = {
            practicename: practice,
            databasename: dbname,
            email: email
        }

        return this._httpClient.post(`${environment.apiUrl}/api/AccountRegister/SendEmailValidation`, payload);
    }

    verifyregistrationcode(practice, dbname, email, code, password) {
        const payload = {
            practicename: practice,
            databasename: dbname,
            email: email,
            code: code,
            password: password
        }

        return this._httpClient.post(`${environment.apiUrl}/api/AccountRegister/VerifyEmail`, payload);
    }

    getAvailSMSNumbers(areacode) {
        return this._httpClient.get(`${environment.apiUrl}/api/AccountRegister/GetSMSNumberByAreaCode/` + areacode);
        // return this._httpClient.get(`${environment.apiUrl}/api/AccountRegister/GetTollFreeSMSNumber/`);
    }

    testopsOrthoPMS(topsIP, topsPort, topsPwd) {
        const payload = {
            topsIP: topsIP,
            topsPort: topsPort,
            topsPwd: topsPwd
        }
        return this._httpClient.post<any>(`${environment.apiUrl}/api/AccountRegister/verifytops`, payload);

    }

    savetopsOrthoPMS(topsIP, topsPort, topsPwd) {
        const payload = {
            topsIP: topsIP,
            topsPort: topsPort,
            topsPwd: topsPwd
        }

        const data = [
            {
                'key': 'pms_server_ip',
                'value': topsIP
            }, {
                'key': 'pms_server_port',
                'value': topsPort
            }, {
                'key': 'pms_server_pwd',
                'value': topsPwd
            }
        ];

        return this._httpClient.post<any>(`${environment.apiUrl}/api/profile/updatetopsip`, data);
    }

    savecloud9PMS(userID, clientID, api_uri, isSD) {
        const payload = {
            userID: userID,
            clientID: clientID,
            API_URI: api_uri,
            isSD: isSD
        }

        return this._httpClient.post(`${environment.apiUrl}/api/Account/savecloud9`, payload);
    }

    savesqlPMS(userID, pms) {
        const payload = {
            userID: userID,
            pms: pms
        }

        return this._httpClient.post(`${environment.apiUrl}/api/Account/savesqlPMS`, payload);
    }

    registerUser(username, practicename, email, pwd, timezone, phone) {
        const payload = {
            PracticeName: practicename,
            Username: username,
            Email: email,
            Password: pwd,
            ConfirmPassword: pwd,
            TimeZone: timezone,
            Phone: phone
        }

        return this._httpClient.post(`${environment.apiUrl}/api/Account/Register`, payload);
    }

    verifyTenantDB(userID) {

        const payload = {
            userID: userID
        }

        return this._httpClient.post(`${environment.apiUrl}/api/Account/VerifyTenant`, payload);
    }


    createTenant(emailName, replyTo, facebookUrl, instagramUrl, youtubeUrl, twitterUrl, googleplusUrl, yelpUrl, enablesettings) {
        const payload = {
            emailName: emailName,
            replyTo: replyTo,
            facebookUrl: facebookUrl,
            instagramUrl: instagramUrl,
            youtubeUrl: youtubeUrl,
            twitterUrl: twitterUrl,
            googleplusUrl: googleplusUrl,
            yelpUrl: yelpUrl,
            enableSettings: enablesettings
        }

        return this._httpClient.post(`${environment.apiUrl}/api/Account/RegisterTenant`, payload);
    }

    configureAccount(data) {
        return this._httpClient.post(`${environment.apiUrl}/api/AccountSetup/configure`, data);
    }

    configureTenant(data) {
        return this._httpClient.post(`${environment.apiUrl}/api/AccountRegister/configuretenant`, data);
    }

}
