<!--Forgot Password Starts-->
<section id="forgot-password">
  <div class="row auth-height full-height-vh m-0 d-flex align-items-center justify-content-center">
    <div class="col-md-7 col-12">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row m-0">
              <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center text-center auth-img-bg py-2">
                <img src="assets/img/gallery/login.png" alt="" class="img-fluid" width="260" height="230">
              </div>
              <div class="col-lg-6 col-md-12 px-4 py-3">
                <h4 class="mb-2 card-title">Recover Password</h4>
                <p class="card-text mb-3">Please enter your email address and we'll send you instructions on how to reset your password.</p>
                <form class="pt-4" (ngSubmit)="onSubmit(f)" #f="ngForm">
                <input type="email" class="form-control mb-3" name="inputEmail" id="inputEmail" placeholder="Email" ngModel>
                <div class="d-flex flex-sm-row flex-column justify-content-between">
                  <a [routerLink]="['/login']" class="btn bg-light-primary mb-2 mb-sm-0">Back To Login</a>
                  <button class="btn btn-primary ml-sm-1" (click)="onSubmit(f)">Recover</button>
                </div>
              </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Forgot Password Ends-->
