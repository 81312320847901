import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, Inject, Renderer2, ViewChild, ElementRef, ViewChildren, QueryList, HostListener } from '@angular/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { DOCUMENT } from '@angular/common';
import { CustomizerService } from '../services/customizer.service';
import { FormControl } from '@angular/forms';
import { LISTITEMS } from '../data/template-search';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { environment } from 'environments/environment';
import * as signalR from '@microsoft/signalr';
import { ToastrService } from 'ngx-toastr';
import { CustomToastComponent } from '../components/custom-toast/custom-toast.component';
import { ChatDataService } from '../services/chatdata.service';
import { ChatMessage } from '../../chat/chat.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {

  // coreSignalR
  private hubConnection: signalR.HubConnection;
  baseUrl = environment.apiUrl;

  currentLang = 'en';
  selectedLanguageText = 'English';
  selectedLanguageFlag = './assets/img/flags/us.png';
  toggleClass = 'ft-maximize';
  placement = 'bottom-right';
  logoUrl = 'assets/img/logo.png';
  menuPosition = 'Side';
  isSmallScreen = false;
  protected innerWidth: any;
  searchOpenClass = '';
  transparentBGClass = '';
  hideSidebar: boolean = true;
  public isCollapsed = true;
  layoutSub: Subscription;
  configSub: Subscription;
  chatmessage: ChatMessage;

  @ViewChild('search') searchElement: ElementRef;
  @ViewChildren('searchResults') searchResults: QueryList<any>;

  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  @Output()
  seachTextEmpty = new EventEmitter<boolean>();

  listItems = [];
  control = new FormControl();
  username = '';
  practicename = '';

  public config: any = {};

  constructor(private layoutService: LayoutService,
    private router: Router,
    public _toastr: ToastrService,
    private _authService: AuthService,
    private _chatDataService: ChatDataService,
    private configService: ConfigService, private cdr: ChangeDetectorRef) {

    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;

    this.layoutSub = layoutService.toggleSidebar$.subscribe(
      isShow => {
        this.hideSidebar = !isShow;
      });

  }

  ngOnInit() {
    this.listItems = LISTITEMS;

    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    } else {
      this.isSmallScreen = false;
    }

    // setup State
    const setupState = this._authService.getSetupState();

    if (setupState > 150) {
      const pms_setup_index = (setupState === 300) ? 1 : 2;
      this._toastr.error('Integration to PMS setup still required.', 'Complete Setup - PMS', {
        disableTimeOut: true,
        closeButton: true,
        positionClass: 'toast-bottom-full-width',
        toastComponent: CustomToastComponent,
        // @ts-ignore
        buttons: [{ title: 'Complete Setup' }]
      })
      .onAction.subscribe(x => {
        this.router.navigate(['/account/settings'], {queryParams: { number: pms_setup_index }});
      })
    }

    if (setupState % 100 === 50) {
      this._toastr.error('SMS Phone Number setup still required.', 'Complete Setup - SMS', {
        disableTimeOut: true,
        closeButton: true,
        positionClass: 'toast-bottom-full-width',
        toastComponent: CustomToastComponent,
        // @ts-ignore
        buttons: [{ title: 'Complete Setup' }]
      })
      .onAction.subscribe(x => {
        this.router.navigate(['/account/settings'], {queryParams: { number: 3 }});
      })
    }

    if (this._authService.getCurrentUser().userName) {
      this.username = this._authService.getCurrentUser().userName;
    }

    if (this._authService.getCurrentUser().practiceName) {
      this.practicename = this._authService.getCurrentUser().practiceName;
    }

    this._chatDataService.currentchatMessage.subscribe(chatmessage => this.chatmessage = chatmessage);

    this.initPushNotification();

  }

  initPushNotification() {

    console.log('SignalR Init...');
    this.hubConnection = new signalR.HubConnectionBuilder() 
    .withUrl(this.baseUrl + '/chatHub' + '?group=' + this._authService.getCurrentUser().tenantId,
     { withCredentials: false } )
    .configureLogging(signalR.LogLevel.Information)
    .withAutomaticReconnect()
    .build();


    Object.defineProperty(WebSocket, 'OPEN', { value: 1, });
    this.hubConnection
    .start()
    .then(() => {
        console.log('SignalR Nav Connection started!!!');
    })
    .catch(err => console.log('Error while starting connection: ' + err));

    // On Chat Message is called when Hub sends message to Client from Server
    this.hubConnection.on('onChatMessage', (clientID, chatmessage) => {
        console.log(clientID);
        this.processIncommingChatMessage(clientID, chatmessage);
    });
}

processIncommingChatMessage (clientID, data) {

      this._chatDataService.changeMessage(data); // Add ChatMessage Object to DataService to be emitted to Chat Component

      if (this.router.url.indexOf('chat') === -1) {
        let popUpMsg = 'New Message: ' + data.from;
        if (data.contactName !== null) {
          popUpMsg = data.contactName + ' ' + data.from;
        }
        this._toastr.show(data.body, popUpMsg, {
          disableTimeOut: true,
          closeButton: true,
          toastComponent: CustomToastComponent,
          // @ts-ignore
          buttons: [{ title: 'Go to Chat' }]
        })
        .onAction.subscribe(x => {
          this.router.navigate(['/chat'], {queryParams: { number: data.from }});
        })
      }
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    })
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.isSmallScreen = true;
    }
    else {
      this.isSmallScreen = false;
    }
  }

  loadLayout() {

    if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() != '') {
      this.menuPosition = this.config.layout.menuPosition;
    }

    if (this.config.layout.variant === 'Light') {
      this.logoUrl = 'assets/img/logo-dark.png';
    }
    else {
      this.logoUrl = 'assets/img/logo.png';
    }

    if (this.config.layout.variant === 'Transparent') {
      this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
    }
    else {
      this.transparentBGClass = '';
    }

  }

  onSearchKey(event: any) {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.add('first-active-item');
    }

    if (event.target.value === '') {
      this.seachTextEmpty.emit(true);
    }
    else {
      this.seachTextEmpty.emit(false);
    }
  }

  removeActiveClass() {
    if (this.searchResults && this.searchResults.length > 0) {
      this.searchResults.first.host.nativeElement.classList.remove('first-active-item');
    }
  }

  onEscEvent() {
    this.control.setValue('');
    this.searchOpenClass = '';
    this.seachTextEmpty.emit(true);
  }

  onEnter() {
    if (this.searchResults && this.searchResults.length > 0) {
      let url = this.searchResults.first.url;
      if (url && url != '') {
        this.control.setValue('');
        this.searchOpenClass = '';
        this.router.navigate([url]);
        this.seachTextEmpty.emit(true);
      }
    }
  }

  redirectTo(value) {
    this.router.navigate([value]);
    this.seachTextEmpty.emit(true);
  }

  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else {
      this.toggleClass = 'ft-maximize';
    }
  }

  toggleSearchOpenClass(display) {
    this.control.setValue('');
    if (display) {
      this.searchOpenClass = 'open';
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    }
    else {
      this.searchOpenClass = '';
    }
    this.seachTextEmpty.emit(true);



  }

  nav(path) {
    this.router.navigate([path]);
  }

  logout() {
    // remove user from local storage to log user out
    this._authService.logout()
      .subscribe(
        () => {},
        (err) => console.error(err),
        () => {
          this.hubConnection.stop();
          this.router.navigate(['/login']);
          console.log('logged out');
          location.reload();
        }
    );
}

  toggleNotificationSidebar() {
    this.layoutService.toggleNotificationSidebar(true);
  }

  toggleSidebar() {
    this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
  }
}
