<div class="container-fluid gradient-indigo-dark-blue">
    <div class="spinner-container" *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>
    <div class="row" style="background-image: url('http://localhost:4200/assets/img/bgtemp.png'); background-repeat:no-repeat; background-size: cover; min-height: 100vh;">
        <div class="col-12 d-flex align-items-center justify-content-center">
            <section id="ngx">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 text-center"> 
                                <img src="assets/img/orthointouch.png" class="img-fluid"> 

                                <!-- <h4 class="head text-center">Create your OrthoInTouch Account</h4> -->
                                <hr>
                            </div>
                        </div>
                        <div class="card-block"> 

                            <form [formGroup]="registrationForm" class="editForm" novalidate>
                                <aw-wizard #wizard navBarLayout="large-empty-symbols" [navBarLocation]="navbarLocation" class="reg-wizard">
                                    
                                    <!-- STEP 0: Product Code-->
                                    <aw-wizard-step [stepTitle]="'Subscription Key'" [navigationSymbol]="{ symbol: '&#xf007;', fontFamily: 'FontAwesome' }">

                                        <div formGroupName="subcriptionKeyForm">                                          

                                            
                                            <div class="row">
                                                <div class='col-12'>
                                                    <div class="form-group  text-center">
                                                        <p></p>
                                                        <p></p>
                                                        <h4>Enter Your Subscription Key</h4>
                                                        <h6>This registration is for orthodontic practices, not patients.  Subcription keys are emailed after signing up for a OrthoInTouch service plan, if you have not yet received a key <a href='mailto:sales@orthointouch.com'>contact us</a></h6>
                                                        <label class="form-control-label" for="subcription_key"></label>
                                                        <input class="form-control input-md col-md-6 mx-auto"
                                                            formControlName="subcription_key" required id="subcription_key"
                                                            name="subcription_key" type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="sk.subcription_key.touched && sk.subcription_key.hasError('required')">A subcription key
                                                            is required to continue!</small>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="form-group text-center">
                                                <button type="button" uiSref="work"
                                                    class="btn btn-info btn-raised"
                                                    [disabled]="!registrationForm.get('subcriptionKeyForm').valid || validKey"
                                                    (click)="validateSubKey()">
                                                    Next
                                                    <span style="margin-left:10px;">
                                                        <i class="ft-chevron-right"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </aw-wizard-step>
                                    
                                    <!-- STEP 1: Practice Details & Validation -->
                                    <aw-wizard-step [stepTitle]="'Practice Details'" (stepEnter)="enterFirstStep($event)"
                                        [navigationSymbol]="{ symbol: '&#xf007;', fontFamily: 'FontAwesome' }">

                                        <div formGroupName="userForm">
                                            <div class="row">
                                                <div class='col-12 col-md-6'>
                                                    <div class="form-group">
                                                        <label class="form-control-label" for="practice_Name">Practice
                                                            Name</label>
                                                        <input class="form-control input-md"
                                                            formControlName="practice_Name" required id="practice_Name"  (ngModelChange)="setUserNameValue($event)"
                                                            name="practice_Name" type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="uf.practice_Name.touched && uf.practice_Name.hasError('required')">This
                                                            field is required!</small>
                                                    </div>
                                                </div>
                                            <!-- </div> -->

                                            
                                            <!-- <div class="row"> -->
                                                <div class='col-12 col-md-6'>
                                                    <div class="form-group">
                                                        <label class="form-control-label" for="email">Email (Verification Required) </label>
                                                        <input class="form-control input-md" formControlName="email"
                                                            id="email" name="email" type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="uf.email.touched && uf.email.hasError('required')">This
                                                            field is required!</small>
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="uf.email.touched && uf.email.hasError('email')">Please
                                                            enter a valid email!</small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class='col-12 col-sm-6'>
                                                    <div class="form-group">
                                                        <label class="form-control-label" for="practicephone">Practice
                                                            Phone (Digits Only)</label>
                                                        <input class="form-control input-md" formControlName="phone"
                                                            id="practicephone" name="practicephone" type="text"
                                                            placeholder="555-672-2955">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="uf.phone.touched && uf.phone.hasError('required')">This
                                                            field is required!</small>
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="uf.phone.touched && uf.phone.hasError('pattern')">Please
                                                            enter a valid phone number!</small>
                                                    </div>
                                                </div>

                                                <div class='col-12 col-sm-6'>
                                                    <div class="form-group">
                                                        <label class="form-control-label" for="timeZone">Time
                                                            Zone:</label>
                                                        <select class="form-control" formControlName="time_Zone"
                                                            id="timeZone" name="timeZone" required>
                                                            <option value="">-- Select your time zone --</option>
                                                            <option value="Hawaiian Standard Time">Hawaii (UTC-10:00)</option>
                                                            <option value="Alaskan Standard Time">Alaska (UTC-09:00)</option>
                                                            <option value="Pacific Standard Time">Pacific Time (US &amp; Canada) (UTC-08:00)</option>
                                                            <option value="US Mountain Standard Time">Arizona (UTC-07:00)</option>
                                                            <option value="Mountain Standard Time">Mountain Time (US &amp; Canada) (UTC-07:00)</option>
                                                            <option value="Central Standard Time">Central Time (US &amp; Canada) (UTC-06:00)</option>
                                                            <option value="Eastern Standard Time">Eastern Time (US &amp; Canada) (UTC-05:00)</option>
                                                            <option value="GMT Standard Time">Dublin, Edinburgh, Lisbon, London (UTC+00:00)</option>
                                                            <option value="W. Australia Standard Time">Perth (UTC+08:00)</option>
                                                            <option value="Cen. Australia Standard Time">Adelaide (UTC+09:30)</option>
                                                            <option value="AUS Central Standard Time">Darwin (UTC+09:30)</option>
                                                            <option value="E. Australia Standard Time">Brisbane (UTC+10:00)</option>
                                                            <option value="AUS Eastern Standard Time">Canberra, Melbourne, Sydney (UTC+10:00)</option>
                                                            <option value="New Zealand Standard Time">Auckland, Wellington (UTC+12:00)</option>
                                                        </select>
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="uf.time_Zone.touched && uf.time_Zone.hasError('required')">This
                                                            field is required!</small>
                                                    </div>
                                                </div>
                                            </div>

                                            
                                            <div class="row">
                                                <div class='col-12 col-md-6'>
                                                    <div class="form-group">
                                                        <label class="form-control-label"
                                                            for="password">Password</label>
                                                        <input class="form-control input-md" ngModel
                                                            formControlName="Password" required id="password"
                                                            name="password" type="password" minlength="6">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="uf.Password.touched && uf.Password.hasError('required')">This
                                                            field is required!</small>
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="uf.Password.touched && uf.Password.hasError('pattern')">Must
                                                            be at least 6 characters, must include one upper case
                                                            ('A'-'Z'), one lower case ('a'-'z'), one digit
                                                            ('0'-'9'), and one special character (@,!,#,)"</small>
                                                    </div>
                                                </div>
                                            
                                                <div class='col-12 col-md-6'>
                                                    <div class="form-group">
                                                        <label class="form-control-label" for="confirmpassword">Confirm
                                                            Password</label>
                                                        <input class="form-control input-md"
                                                            formControlName="ConfirmPassword" required
                                                            id="confirmpassword" name="confirmpassword" type="password"
                                                            minlength="6">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="uf.ConfirmPassword.touched && uf.ConfirmPassword.hasError('required')">This
                                                            field is required!</small>
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="uf.ConfirmPassword.touched && uf.ConfirmPassword.hasError('pattern')">Must
                                                            be at least 6 characters, must include one upper case
                                                            ('A'-'Z'), one lower case ('a'-'z'), one digit
                                                            ('0'-'9'), and one special character (@,!,#,)"</small>
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="uf.ConfirmPassword.touched && uf.ConfirmPassword.hasError('mustMatch')">Passwords
                                                            do not match!</small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row" style="display: none;">
                                                <div class='col-12'>
                                                    <div class="form-group">
                                                        <label class="form-control-label" for="username">User
                                                            Name</label>
                                                        <input class="form-control input-md" formControlName="UserName"
                                                            required id="username" name="username" type="text" value=[()]>
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="uf.UserName.touched && uf.UserName.hasError('required')">This
                                                            field is required!</small>
                                                    </div>
                                                </div>
                                            </div>



                                            <div *ngIf="validateEmailShow" class="form-group text-center">
                                                <button type="button" uiSref="work" class="btn btn-info btn-raised"
                                                    [disabled]="!registrationForm.get('userForm').valid"
                                                    (click)="sendValidationCode()">
                                                    Verify Email
                                                    <span style="margin-left:10px;">
                                                        <i class="ft-chevron-right"></i>
                                                    </span>
                                                </button>
                                            </div>

                                            <div class="row"
                                                *ngIf="isEmailCodeSent && registrationForm.get('userForm').valid">
                                                <hr>
                                                <div class='col-12'>
                                                    <div class="form-group">
                                                        <label class="form-control-label" for="verificationcode">Enter
                                                            the
                                                            <strong>4 digit verification code</strong> sent to
                                                            {{ uf.email.value }}:</label>
                                                        <input class="form-control input-sm-6" #verificationcode 
                                                        [(ngModel)]="emailVerificationCode" required
                                                            id="verificationcode" name="verificationcode" type="text"
                                                            [ngModelOptions]="{standalone: true}">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="!verificationcode.valid && (verificationcode.dirty || verificationcode.touched)">Please
                                                            enter the verification code!</small>
                                                    </div>
                                                </div>

                                                <div class="col-12">
                                                    <div class="form-group text-center">
                                                        <button type="button" uiSref="work"
                                                            class="btn btn-info btn-raised"
                                                            [disabled]="!emailVerificationCode || disableNext"
                                                            (click)="validateEmailCode()">
                                                            Next
                                                            <span style="margin-left:10px;">
                                                                <i class="ft-chevron-right"></i>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </aw-wizard-step>

                                    <!-- Step 2: Select your PMS  -->
                                    <aw-wizard-step [stepTitle]="'Management System'"
                                        [navigationSymbol]="{ symbol: '&#xf007;', fontFamily: 'FontAwesome' }">
                                        <div formGroupName="phoneForm">
                                            <div class="row">
                                                <div class="col-12 text-center">
                                                    <label class="form-control-label" for="timeZone">Practice
                                                        Management System</label>
                                                </div>
                                            </div>
                                            
                                            <div class="row">
                                                <div class="col-xl-3 col-lg-6 col-12">
                                                  <div class="card card-inverse" style="background-color: #EA698B; cursor: pointer;">
                                                    <div class="card-content" (click)="setPMS('topsOrtho')">
                                                      <div class="card-body">
                                                        <div class="media">
                                                          <div class="media-body text-center">
                                                            <h3 class="card-text">topsOrtho</h3>
                                                          </div>
                                                          <!-- <div class="media-right align-self-center">
                                                            <img class="float-right" src="assets/img/gallery/topsortho.png" alt="img12" />
                                                            <i class="ft-briefcase font-large-2 float-right"></i> 
                                                          </div> -->
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-6 col-12">
                                                  <div class="card card-inverse" style="background-color: #F5B913; cursor: pointer;">
                                                    <div class="card-content" (click)="setPMS('OrthoTrac')">
                                                      <div class="card-body">
                                                        <div class="media">
                                                          <div class="media-body text-center">
                                                            <h3 class="card-text">OrthoTrac</h3>
                                                          </div>
                                                          <!-- <div class="media-right align-self-center">
                                                            <i class="ft-user font-large-2 float-right"></i>
                                                          </div> -->
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-6 col-12">
                                                  <div class="card card-inverse" role="button" style="background-color: #005B96; cursor: pointer;">
                                                    <div class="card-content" (click)="setPMS('Cloud9Ortho')">
                                                      <div class="card-body">
                                                        <div class="media">
                                                          <div class="media-body text-center">
                                                            <h3 class="card-text">Cloud9 Ortho</h3>
                                                          </div>
                                                          <!-- <div class="media-right align-self-center">
                                                            <i class="ft-pie-chart font-large-2 float-right"></i>
                                                          </div> -->
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="col-xl-3 col-lg-6 col-12">
                                                  <div class="card card-inverse bg-danger" style="cursor: pointer;">
                                                    <div class="card-content" (click)="setPMS('SmileDoctors')">
                                                      <div class="card-body">
                                                        <div class="media">
                                                          <div class="media-body text-center">
                                                            <h3 class="card-text">Smile Doctors</h3>
                                                            
                                                          </div>
                                                          <!-- <div class="media-right align-self-center">
                                                            <i class="ft-life-buoy font-large-2 float-right"></i>
                                                          </div> -->
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                            </div>

                                            <input class="form-control input-md invisible" formControlName="pms"
                                                        id="pms" name="pms" type="text">

                                            <!-- <div class="row mb-2">
                                                <div class="col-3 col-md-2">
                                                    <label class="form-control-label" for="areacode">
                                                        Area Code</label>
                                                    <input class="form-control input-md" formControlName="areaCode"
                                                        id="areacode" name="areacode" type="text" minlength="3">
                                                </div>
                                                <div class="col-2 col-md-1 d-flex align-items-end">
                                                    <button type="button" uiSref="work"
                                                        class="btn btn-info btn-raised mb-0"
                                                        (click)="getNumbersByAreaCode()">
                                                        <span>
                                                            <i class="ft-chevron-right"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                                <div class="col-7 col-md-3 d-flex align-items-end">
                                                    <select class="form-control" formControlName="sms_phone_number">
                                                        <option value="">-- Select SMS Phone --</option>
                                                        <option *ngFor="let pn of numbersByCode"
                                                            [value]="pn.phone_number">
                                                            {{ pn.phone_number }}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col-12 col-md-3 d-flex align-items-end">
                                                    <small class="form-text text-muted danger"
                                                        *ngIf="pf.sms_phone_number.touched && pf.sms_phone_number.hasError('required')">This
                                                        field is required!</small>
                                                </div>
                                            </div> -->

                                            

                                            <!-- <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group text-center">
                                                        <button type="button" uiSref="work" awNextStep 
                                                            class="btn btn-info btn-raised"
                                                            [disabled]="!registrationForm.get('phoneForm').valid">
                                                            Next
                                                            <span style="margin-left:10px;">
                                                                <i class="ft-chevron-right"></i>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div> -->
                                        </div>
                                    </aw-wizard-step>

                                    <!-- Step 3: PMS Details -->
                                    <aw-wizard-step [stepTitle]="'PMS Details'"
                                        [navigationSymbol]="{ symbol: '&#xF233;', fontFamily: 'FontAwesome' }" (stepEnter)="enterPMSDetails($event)">

                                        <!-- TopsOrtho Details -->
                                        <ng-container formGroupName="topsOrthoForm" *ngIf="pf.pms.value == 'topsOrtho'">
                                            <div class="row">
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="control-label">topsOrtho IP
                                                            Address</label>
                                                        <input class="form-control input-md"
                                                            formControlName="topsIP" placeholder="(eg. 67.174.33.102)"
                                                            type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="tf.topsIP.touched && tf.topsIP.hasError('required')">Please
                                                            enter your topsOrtho Server IP address</small>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="control-label">topsOrtho Port</label>
                                                        <input class="form-control input-md" id="topsPort"
                                                        formControlName="topsPort" placeholder="(eg. 5432)" value="5432"
                                                            type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="tf.topsPort.touched && tf.topsPort.hasError('required')">Please
                                                            enter the topsOrtho Port</small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="form-group col-sm-6 col-md-6">
                                                    <label class="control-label">Service Password:</label>
                                                    <input class="form-control input-md" id="topsPwd"
                                                        formControlName="topsPwd"
                                                        placeholder="topsOrtho Service Password" type="password">
                                                    <small class="form-text text-muted danger"
                                                        *ngIf="tf.topsPwd.touched && tf.topsPwd.hasError('required')">topsOrtho
                                                        service password Required</small>
                                                </div>
                                                <div class="form-group col-sm-6 col-md-6">
                                                    <label class="control-label">Confirm Service
                                                        Password:</label>
                                                    <input class="form-control input-md" id="confirmtopsPwd"
                                                        formControlName="confirmtopsPwd"
                                                        placeholder="Confirm Service Password" type="password">
                                                    <small class="form-text text-muted danger"
                                                        *ngIf="tf.confirmtopsPwd.touched && tf.confirmtopsPwd.hasError('required')">topsOrtho
                                                        Password Required</small>
                                                    <small class="form-text text-muted danger"
                                                        *ngIf="tf.confirmtopsPwd.touched && tf.confirmtopsPwd.hasError('mustMatch')">Passwords
                                                        do not match!</small>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <a href="https://youtu.be/olqMrjjVni0" target="_blank"
                                                            data-toggle="modal">How to enable OrthoInTouch within
                                                            topsOrtho (2 min video)</a>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group text-center">
                                                        <button (click)="testTopsConnection()" type="button"
                                                            class="btn btn-raised btn-info"
                                                            [disabled]="!registrationForm.get('topsOrthoForm').valid">Test
                                                            Connection</button> &nbsp;
                                                            <button type="button" uiSref="work" *ngIf="!topsConnectionSucceed"
                                                            class="btn btn-outline-info btn-round" awNextStep>
                                                            Continue (setup later)
                                                            <span style="margin-left:10px;">
                                                                <i class="ft-chevron-right"></i>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row"
                                                *ngIf="registrationForm.get('topsOrthoForm').valid && topsConnectionSucceed">
                                                <div class="col-12">
                                                    <div class="form-group text-center">
                                                        <button uiSref="personal" type="button"
                                                            class="btn btn-raised btn-secondary mr-1" awPreviousStep>
                                                            <span style="margin-right:10px;">
                                                                <i class="ft-chevron-left"></i>
                                                            </span> Previous</button>
                                                        <button type="button" uiSref="work"
                                                            class="btn btn-info btn-raised" awNextStep>
                                                            Next
                                                            <span style="margin-left:10px;">
                                                                <i class="ft-chevron-right"></i>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </ng-container>

                                        <!-- Cloud9 Details -->
                                        <ng-container formGroupName="cloud9Form" *ngIf="pf.pms.value == 'Cloud9Ortho' || pf.pms.value == 'SmileDoctors'">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label class="control-label">Cloud9 API URL
                                                            (optional)</label>
                                                        <input class="form-control input-md" id="cloud9_api_uri"
                                                            formControlName="cloud9_API_URI"
                                                            placeholder="(eg. https://atl-partner.cloud9ortho.com/GetData.ashx)"
                                                            type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="cf.cloud9_API_URI.touched && cf.cloud9_API_URI.hasError('required')">Please
                                                            enter the Cloud 9 URI</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="control-label">Cloud9 Client ID</label>
                                                        <input class="form-control input-md" id="cloud9_clientID"
                                                            formControlName="cloud9_ClientID"
                                                            placeholder="(eg. B205C71B-B74B-45FA-93C0-F61D200F5605)"
                                                            type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="cf.cloud9_ClientID.touched && cf.cloud9_ClientID.hasError('required')">Please
                                                            enter your Cloud9 Client ID</small>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="control-label">Cloud9 API
                                                            UserName:</label>
                                                        <input class="form-control input-md" id="cloud9_userName"
                                                            formControlName="cloud9_UserName"
                                                            placeholder="Cloud9 API Username" type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="cf.cloud9_UserName.touched && cf.cloud9_UserName.hasError('required')">Cloud9
                                                            Username Required</small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="control-label">Cloud9 API
                                                            Password:</label>
                                                        <input class="form-control input-md" id="cloud9_password"
                                                            formControlName="cloud9_Password"
                                                            placeholder="Cloud9 API Password" type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="cf.cloud9_Password.touched && cf.cloud9_Password.hasError('required')">Cloud9
                                                            Password Required</small>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="control-label">Confirm Cloud9 API
                                                            Password:</label>
                                                        <input class="form-control input-md"
                                                            id="cloud9_confirm_password"
                                                            formControlName="cloud9_Confirm_Password"
                                                            placeholder="Confirm Service Password" type="password">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="cf.cloud9_Confirm_Password.touched && cf.cloud9_Confirm_Password.hasError('required')">
                                                            Password Required</small>
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="cf.cloud9_Confirm_Password.touched && cf.cloud9_Confirm_Password.hasError('mustMatch')">Passwords
                                                            do not match!</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group text-center">
                                                        <button uiSref="personal" type="button"
                                                            class="btn btn-raised btn-secondary mr-1" awPreviousStep>
                                                            <span style="margin-right:10px;">
                                                                <i class="ft-chevron-left"></i>
                                                            </span> Previous</button>
                                                        <button type="button" uiSref="work"
                                                            class="btn btn-info btn-raised" awNextStep
                                                            [disabled]="!registrationForm.get('cloud9Form').valid">
                                                            Next
                                                            <span style="margin-left:10px;">
                                                                <i class="ft-chevron-right"></i>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </ng-container>

                                        <!-- OrthoTrac Details -->
                                        <ng-container formGroupName="dolphinForm" *ngIf="pf.pms.value == 'OrthoTrac'">
                                            <div class="row">
                                                <div class="col-12 col-md-6 d-flex align-items-center">
                                                    <div class="form-group d-flex align-items-center">
                                                        <input type="checkbox"
                                                            (change)="dConnChange($event.target.checked)"
                                                            formControlName="dolphin_Custom_Connection" />
                                                        <label class="ml-1 mb-0">Custom Connection </label>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6" *ngIf="df.dolphin_Custom_Connection.value">
                                                    <div class="form-group">
                                                        <label class="control-label">Custom Connection String:</label>
                                                        <input class="form-control input-md"
                                                            id="dolphin_Custom_Connection_String"
                                                            formControlName="dolphin_Custom_Connection_String"
                                                            placeholder="Data Source" type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="df.dolphin_Custom_Connection_String.touched && df.dolphin_Custom_Connection_String.hasError('required')">
                                                            Custom Connection String Required</small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="control-label">Dolphin Server:</label>
                                                        <input class="form-control input-md" id="dolphin_DataSource"
                                                            formControlName="dolphin_DataSource"
                                                            placeholder="(eg. 192.168.0.1\Dolphin)" type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="df.dolphin_DataSource.touched && df.dolphin_DataSource.hasError('required')">
                                                            Dolphin Server Required</small>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="control-label">Dolphin Database:</label>
                                                        <input class="form-control input-md" id="dolphin_DB"
                                                            formControlName="dolphin_DB" placeholder="Dolphin Platform"
                                                            type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="df.dolphin_DB.touched && df.dolphin_DB.hasError('required')">
                                                            Dolphin Database Required</small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-12 col-md-6 d-flex align-items-center">
                                                    <div class="form-group d-flex align-items-center">
                                                        <input type="checkbox" formControlName="dolphin_Windows_Auth"
                                                            (change)="dWinAuthChange($event.target.checked)" />
                                                        <label class="ml-1 mb-0">Windows Authentication </label>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6" *ngIf="df.dolphin_Windows_Auth.value">
                                                    <div class="form-group">
                                                        <label class="control-label">Database User:</label>
                                                        <input class="form-control input-md" id="dolphin_DB_User_ID"
                                                            formControlName="dolphin_DB_User_ID"
                                                            placeholder="Database Username" type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="df.dolphin_DB_User_ID.touched && df.dolphin_DB_User_ID.hasError('required')">
                                                            Dolphin DB User ID Required</small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row" *ngIf="df.dolphin_Windows_Auth.value">
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="control-label">Dolphin DB Password:</label>
                                                        <input class="form-control input-md" id="dolphin_DB_Password"
                                                            formControlName="dolphin_DB_Password"
                                                            placeholder="Database Password" type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="df.dolphin_DB_Password.touched && df.dolphin_DB_Password.hasError('required')">
                                                            Dolphin DB Password Required</small>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="control-label">Confirm Password:</label>
                                                        <input class="form-control input-md"
                                                            id="dolphin_DB_Confirm_Password"
                                                            formControlName="dolphin_DB_Confirm_Password"
                                                            placeholder="Database Password" type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="df.dolphin_DB_Confirm_Password.touched && df.dolphin_DB_Confirm_Password.hasError('required')">
                                                            Confirm Password Required</small>
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="df.dolphin_DB_Confirm_Password.touched && df.dolphin_DB_Confirm_Password.hasError('mustMatch')">
                                                            Passwords do not match!</small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group text-center">
                                                        <button uiSref="personal" type="button"
                                                            class="btn btn-raised btn-secondary mr-1" awPreviousStep>
                                                            <span style="margin-right:10px;">
                                                                <i class="ft-chevron-left"></i>
                                                            </span> Previous</button>
                                                        <button type="button" uiSref="work"
                                                            class="btn btn-info btn-raised" awNextStep
                                                            [disabled]="!registrationForm.get('dolphinForm').valid">
                                                            Next
                                                            <span style="margin-left:10px;">
                                                                <i class="ft-chevron-right"></i>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </aw-wizard-step>

                                    <!-- STEP 4: Notification Options -->
                                    <aw-wizard-step [stepTitle]="'Social Media'"
                                        [navigationSymbol]="{ symbol: '&#xf0ac;', fontFamily: 'FontAwesome' }" (stepEnter)="enterLastStep($event)">
                                        <ng-container formGroupName="settingsForm">

                                            <div class="row">
                                                <div class='col-12'>
                                                    <div class="form-group  text-center">
                                                        <p></p>
                                                        <p></p>
                                                        <h4>Social Media Links</h4>
                                                        <h6>Optionally, provide the web addresses to your social media pages.  Links to these pages will be added in the footer of your email templates.</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <!-- Hide : d-none -->
                                            <div class="row d-none">
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="control-label"
                                                            for="practice_location_name">Location Name:</label>
                                                        <input class="form-control input-md" id="practice_location_name"
                                                            formControlName="practice_location_name"
                                                            placeholder="Office Location" type="text">
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6">
                                                    <div class="form-group">
                                                        <label class="control-label" for="contact_name">Contact
                                                            Name:</label>
                                                        <input class="form-control input-md" id="contact_name"
                                                            formControlName="contact_name" placeholder="Contact Name"
                                                            type="text">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row d-none">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label class="control-label" for="address">Address:</label>
                                                        <input class="form-control input-md" id="address"
                                                            formControlName="address" placeholder="Address" type="text">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row d-none">
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label class="control-label" for="address">City:</label>
                                                        <input class="form-control input-md" id="city"
                                                            formControlName="city" placeholder="City" type="text">
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label class="control-label" for="state">State:</label>
                                                        <input class="form-control input-md" id="state"
                                                            formControlName="state" placeholder="State" type="text">
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="form-group">
                                                        <label class="control-label" for="zip">Zip:</label>
                                                        <input class="form-control input-md" id="zip"
                                                            formControlName="zip" placeholder="Zip" type="text">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group">
                                                <label class="form-control-label" for="facebookUrl">Facebook
                                                    Link:</label>
                                                <input class="form-control input-md" id="facebookUrl"
                                                    formControlName="facebook_url" type="text">
                                            </div>
                                            <div class="form-group">
                                                <label class="form-control-label" for="instagramUrl">Instagram
                                                    Link:</label>
                                                <input class="form-control input-md" formControlName="instagram_url"
                                                    id="instagramUrl" name="instagramUrl" type="text">
                                            </div>
                                            <div class="form-group">
                                                <label class="form-control-label" for="youtubeUrl">Youtube Link:</label>
                                                <input class="form-control input-md" formControlName="youtube_url"
                                                    id="youtubeUrl" name="youtubeUrl" type="text">
                                            </div>
                                            <div class="form-group">
                                                <label class="form-control-label" for="twitterUrl">Twitter Link:</label>
                                                <input class="form-control input-md" formControlName="twitter_url"
                                                    id="twitterUrl" name="twitterUrl" type="text">
                                            </div>
                                            <div class="form-group">
                                                <label class="form-control-label" for="googleplusUrl">Google+
                                                    Link:</label>
                                                <input class="form-control input-md" formControlName="google_plus_url"
                                                    id="googleplusUrl" name="googleplusUrl" type="text">
                                            </div>
                                            <div class="form-group">
                                                <label class="form-control-label" for="yelpUrl">Yelp Link:</label>
                                                <input class="form-control input-md" formControlName="yelp_url"
                                                    id="yelpUrl" name="yelpUrl" type="text">
                                            </div>

                                            <div class="row d-none">
                                                <div class="col-12 col-md-3 d-flex align-items-center">
                                                    <div class="form-group d-flex align-items-center">
                                                        <input type="checkbox" formControlName="template_reminder" />
                                                        <label class="ml-1 mb-0">Reminder Template </label>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-3 d-flex align-items-center">
                                                    <div class="form-group d-flex align-items-center">
                                                        <input type="checkbox" formControlName="template_missed" />
                                                        <label class="ml-1 mb-0">Missed Template </label>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-3 d-flex align-items-center">
                                                    <div class="form-group d-flex align-items-center">
                                                        <input type="checkbox" formControlName="template_recall" />
                                                        <label class="ml-1 mb-0">Recall Template </label>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-3 d-flex align-items-center">
                                                    <div class="form-group d-flex align-items-center">
                                                        <input type="checkbox" formControlName="template_followup" />
                                                        <label class="ml-1 mb-0">Follow Up Template </label>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-3 d-flex align-items-center">
                                                    <div class="form-group d-flex align-items-center">
                                                        <input type="checkbox" formControlName="template_birthday" />
                                                        <label class="ml-1 mb-0">Birthday Template </label>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-3 d-flex align-items-center">
                                                    <div class="form-group d-flex align-items-center">
                                                        <input type="checkbox" formControlName="template_retainer" />
                                                        <label class="ml-1 mb-0">Retainer Template </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group text-center">
                                                        <button uiSref="personal" type="button"
                                                            class="btn btn-raised btn-secondary mr-1" awPreviousStep>
                                                            <span style="margin-right:10px;">
                                                                <i class="ft-chevron-left"></i>
                                                            </span> Previous</button>
                                                        <button type="button" uiSref="work"
                                                            class="btn btn-info btn-raised" awNextStep
                                                            [disabled]="!registrationForm.get('settingsForm').valid"
                                                            (click)="configureAccount()">
                                                            Finish
                                                            <span style="margin-left:10px;">
                                                                <i class="ft-chevron-right"></i>
                                                            </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </aw-wizard-step>


                                    <!-- STEP 5: Complete -->
                                    <aw-wizard-step [stepTitle]="'Complete'" [navigationSymbol]="{ symbol: '&#xf007;', fontFamily: 'FontAwesome' }">
                                        <div class="row">
                                            <div class='col-12'>
                                                <div class="form-group  text-center">
                                                    <p></p>
                                                    <p></p>
                                                    <h4>Congratulations!</h4>
                                                    <p></p>
                                                    <p><br></p>
                                                    <h6>Registration is now complete and portal setup is underway.  This process takes ~3-5 minutes.</h6>
                                                    <p><br></p>
                                                    <p></p>
                                                    <h6>Once your portal is setup, login details will be emailed to you.</h6>
                                                    <p></p>
                                                    <p><br></p>
                                                    <h6>You can contact <a href='mailto:support@orthointouch.com'>OrthoInTouch Support</a> with any questions/concerns.</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <p></p>
                                                    <p><br></p>
                                        <div class="form-group text-center">
                                            <button type="button" uiSref="work"
                                                class="btn btn-info btn-raised"
                                                onclick="window.location.href='https://www.orthointouch.com/onlinetutorial'"
                                                >
                                                OrthoInTouch Online Tutorial
                                            </button>
                                        </div>
                                </aw-wizard-step>

                                </aw-wizard>
                            </form>


                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>