// import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
// import { Injectable } from '@angular/core';
// import { AuthService } from './auth.service';

// @Injectable()
// export class AuthGuard implements CanActivate {

//   constructor(private authService: AuthService, private router: Router) { }

//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
//     let isAuth = this.authService.isAuthenticated();
//     if (!isAuth) {
//       this.router.navigate(['/pages/login']);
//     }
//     else {
//       return true;
//     }
//   }
// }


import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AdalService } from 'adal-angular4';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private authService: AuthService,
    private adal: AdalService
    ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const allowedRoles = route.data.allowedRoles;
    const currentUser = this.authService.getCurrentUser();
        if (currentUser) {
          if (allowedRoles.length > 0) {
            // logged in so return true
            const found = currentUser.roles.some(r => allowedRoles.indexOf(r.name.toLowerCase()) >= 0);
            // const found = currentUser.roles.some(r => allowedRoles.indexOf(r.name.toLowerCase()) >= 0);
            // const found = true;
            if (found) {
                // logged in so return true
                return true;
            } else {
              console.log('navigating to login');
                this.router.navigate(['/login']);
                return false;
            }
          } else {
            return true;
          }
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        return false;
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const allowedRoles = route.data.allowedRoles;
    const currentUser = this.authService.getCurrentUser();
        if (currentUser) {
          if (allowedRoles.length > 0) {
            // logged in so return true
            const found = currentUser.roles.some(r => allowedRoles.indexOf(r.name.toLowerCase()) >= 0);
            // const found = currentUser.roles.some(r => allowedRoles.indexOf(r.toLowerCase()) >= 0);
            // const found = true;
            if (found) {
                // logged in so return true
                return true;
            } else {
              console.log('navigating to login');
                this.router.navigate(['/login']);
                return false;
            }
          } else {
            return true;
          }
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        return false;
  }
}
