<!--Login Page Starts-->
<section id="login">
    <div class="container-fluid gradient-indigo-dark-blue">
        <div class="row full-height-vh">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="card gradient-blue-grey-blue-grey text-center width-400">
                    <div class="card-img overlap">
                        <img alt="element 06" class="mb-1" src="assets/img/portrait/avatars/logo.png" width="190">
                    </div>
                    <div class="card-body">
                        <div class="card-block">
                            <h2 class="white">Select Instance</h2>
                                <div class="form-group" *ngIf="loginError">
                                    <div class="row" >
                                        <div class="col-md-12">
                                            <div class="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0 ml-3">
                                                <label class="danger float-left" [innerText]="loginError"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-md-12">
                                        <select class="form-control" [(ngModel)]="selected_instance">
                                            <option value="-1">--select instance--</option>
                                            <option *ngFor="let item of instances"  [value]="item.value">{{item.key}}</option>
                                        </select>
                                    </div>
                                </div>                               
                                <div class="form-group">
                                    <div class="col-md-12">
                                        <button type="submit" (click)="onContinue()"  class="btn btn-info btn-block btn-raised">Continue</button>
                                        <button type="button" (click)="onLogout()" class="btn btn-secondary btn-block btn-raised">Logout</button>
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <!--<div class="align-items-center"><a (click)="onForgotPassword()" class="white">Recover Password</a></div>
                         <div class="float-right"><a (click)="onRegister()" class="white">New User?</a></div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Login Page Ends-->