import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';

import { NgbModule, NgbDateParserFormatter, NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DragulaService } from 'ng2-dragula';
import { NgxSpinnerModule } from 'ngx-spinner';

import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import * as $ from 'jquery';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { FullLayoutComponent } from './layouts/full/full-layout.component';

import { JwtInterceptor } from './shared/auth/jwt.interceptor';
import { CustomMaterialModule } from './shared/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ArchwizardModule } from 'angular-archwizard';

import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth-guard.service';
import { AuthCallbackComponent } from './shared/adal/ad-auth-callback.component';
import { WINDOW_PROVIDERS } from './shared/services/window.service';
import { AdalService } from 'adal-angular4';

import { MembershipModule } from './orthointouch/membership/membership.module';
import { membershipmodals } from './orthointouch/membership/shared/membership-declarations.lib';
import { CustomDateParserFormatter, CustomDateAdapter } from './shared/data/dateformat';
import { RegisterComponent } from './orthointouch/account/register/register.component';

import { ResetPasswordPageComponent } from './orthointouch/account/reset-password/reset-password.component';
import { MessagingService } from './shared/services/messaging.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ForgotPasswordPageComponent } from './orthointouch/account/forgot-password/forgot-password-page.component';
import { LoginPageComponent } from './orthointouch/account/login/login-page.component';
import { ChatDataService } from './shared/services/chatdata.service';

const firebaseConfig = {
  // apiKey: 'YOUR_API_KEY', //YOUR_API_KEY
  // authDomain: 'YOUR_AUTH_DOMAIN', //YOUR_AUTH_DOMAIN
  // databaseURL: 'YOUR_DATABASE_URL', //YOUR_DATABASE_URL
  // projectId: 'YOUR_PROJECT_ID', //YOUR_PROJECT_ID
  // storageBucket: 'YOUR_STORAGE_BUCKET', //YOUR_STORAGE_BUCKET
  // messagingSenderId: 'YOUR_MESSAGING_SENDER_ID', //YOUR_MESSAGING_SENDER_ID
  // appId: 'YOUR_APP_ID', //YOUR_APP_ID
  // measurementId: 'YOUR_MEASUREMENT_ID' //YOUR_MEASUREMENT_ID

  apiKey: 'AIzaSyBc3FY7Qh5f79MgDrmCUr-KvfNjrYRCm7c',
  authDomain: 'oit-console-notifications.firebaseapp.com',
  databaseURL: 'https://oit-console-notifications.firebaseio.com',
  projectId: 'oit-console-notifications',
  storageBucket: 'oit-console-notifications.appspot.com',
  messagingSenderId: '916674151034',
  appId: '1:916674151034:web:c5e8abf33690a49a99b1c3',
  measurementId: 'G-F78BBB09EV'
};


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    AuthCallbackComponent,
    RegisterComponent,
    LoginPageComponent,
    ForgotPasswordPageComponent,
    ResetPasswordPageComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    AngularFireMessagingModule,
    ToastrModule.forRoot(),
    NgbModule,
    ArchwizardModule,
    CustomMaterialModule,
    NgxSpinnerModule,
    DeviceDetectorModule.forRoot(),
    PerfectScrollbarModule,
    InfiniteScrollModule,
    MembershipModule
  ],
  entryComponents: [...membershipmodals],
  providers: [
    AuthService,
    AuthGuard,
    AdalService,
    MessagingService,
    ChatDataService,
    DragulaService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    WINDOW_PROVIDERS,
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDateAdapter, useClass: CustomDateAdapter }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
