<!--Forgot Password Starts-->
<section id="reset-password">
    <div class="container-fluid gradient-indigo-dark-blue">
        <div class="row text-left full-height-vh">
            <div class="col-12 d-flex align-items-center justify-content-center">
                <div class="card bg-blue-grey bg-darken-3 px-4">
                    <div class="card-header">
                        <div class="card-image text-center">
                            <img src="assets/img/gallery/login.png" alt="" class="img-fluid" width="260" height="230">
                            <!-- <i class="icon-key font-large-5 blue-grey lighten-4"></i> -->
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="card-block">
                            <div class="text-center">
                                <h4 class="text-uppercase text-bold-400 white">Reset Password</h4>
                            </div>
                            <form class="pt-4" (ngSubmit)="onSubmit()" #f="ngForm">
                                <div class="form-group">
                                    <input type="email" class="form-control" name="inputUsername" id="inputUsername" placeholder="Your Email" ngModel>
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control" name="inputPassword" id="inputPassword" placeholder="New Password" ngModel>
                                </div>
                                <div class="form-group">
                                    <input type="password" class="form-control" name="inputConfirmPassword" id="inputConfirmPassword" placeholder="Confirm New Password" ngModel>
                                </div>
                                <div class="form-group pt-2">
                                    <div class="text-center mt-3">
                                        <button type="button" (click)="onSubmit()" class="btn btn-primary btn-raised btn-block">Reset</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card-footer bg-blue-grey bg-darken-3">
                            <div class="text-center white"><a (click)="onLogin()">Login</a></div>
                            <!-- <div class="float-right white">New User? <a (click)="onRegister()">Register Now</a></div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--Forgot Password Ends-->