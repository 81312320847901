import { Component, OnInit } from '@angular/core';
import { Toast, ToastrService, ToastPackage, IndividualConfig } from 'ngx-toastr';

@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast.component.html',
  styleUrls: ['./custom-toast.component.scss']
})
export class CustomToastComponent extends Toast implements OnInit {
  btns = [];
  constructor(protected toastrService: ToastrService, public toastPackage: ToastPackage) {
    super(toastrService, toastPackage);
  }

  ngOnInit(): void {
    // @ts-ignore
    this.btns = this.options.buttons;
  }

  action(event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    this.toastrService.clear();
    return false;
  }

}
