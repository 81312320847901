import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogData } from './confirmation-dialog-data';
import { ResponseBase } from '../../models/base';

@Component({
  selector: 'app-confirmation-modal-dialog',
  templateUrl: './confirmation-modal-dialog.component.html',
  styleUrls: ['./confirmation-modal-dialog.component.css']
})
export class ConfirmationModalDialogComponent implements OnInit {
  response: ResponseBase;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
  ) {
    this.response = new ResponseBase();
  }

  ngOnInit() {}

  save() {
    this.response.success = true;
    this.response.data = this.data;
    this.dialogRef.close(this.response);
  }

  close() {
    this.dialogRef.close(this.response);
  }
}
