import { NgModule } from '@angular/core';
import { ConfirmationModalDialogComponent } from './confirmation-modal-dialog/confirmation-modal-dialog.component';
import { SharedModule } from 'app/shared/shared.module';
import { CustomMaterialModule } from 'app/shared/material.module';

@NgModule({
  imports: [SharedModule, CustomMaterialModule],
  declarations: [ConfirmationModalDialogComponent],
  exports: [ConfirmationModalDialogComponent]
})
export class MembershipModalsModule {}
