<!--Login Page Starts-->
<section id="login">
  <div class="row auth-height full-height-vh m-0">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row m-0">
              <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                <img src="assets/img/gallery/login.png" alt="" class="img-fluid" width="300" height="230">
              </div>

              <div class="col-lg-6 col-12 px-4 py-3">


                <h4 class="mb-2 card-title">Login</h4>
                <p>Welcome back, please login to your account.</p>

                <!-- LOGIN -->

                <!-- Redriect to Subdoamin -->
                <div class="login-options" *ngIf="isConsoleUrl()">
                  <form [formGroup]="lookupForm">
                    <div class="form-group">
                      <input type="text" formControlName="email" class="form-control" placeholder="Email" (keyup)="filterUpdate($event)"
                        [ngClass]="{ 'is-invalid': lookupFormSubmitted && lf.email.invalid, 'is-valid': lookupFormSubmitted && !lf.email.invalid }"
                        required>
                      <div *ngIf="lookupFormSubmitted && (lf.email.invalid || lf.email.errors?.required)"
                        class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Email is required</div>
                    </div>
                    <div class="d-flex justify-content-between flex-sm-row flex-column">
                      <a  class="btn btn-primary" (click)="onContinue()">Continue</a>
                    </div>
                  </form>
                </div>

                <ngb-alert type="light-danger" class="mb-2" *ngIf="isLoginFailed" (close)="isLoginFailed = false">
                  <p class="mb-0">Login failed!</p>
                </ngb-alert>
                <form [formGroup]="loginForm"  *ngIf="!isConsoleUrl()">
                  <div class="form-group">
                    <input type="text" formControlName="username" class="form-control" placeholder="Email"
                      [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.username.invalid, 'is-valid': loginFormSubmitted && !lf.username.invalid }"
                      required>
                    <div *ngIf="loginFormSubmitted && (lf.username.invalid || lf.username.errors?.required)"
                      class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> This is
                      required</div>
                  </div>
                  <div class="form-group">
                    <input type="password" formControlName="password" class="form-control" placeholder="Password"
                      [ngClass]="{ 'is-invalid': loginFormSubmitted && lf.password.invalid, 'is-valid': loginFormSubmitted && !lf.password.invalid }"
                      required>
                    <div *ngIf="loginFormSubmitted && (lf.password.invalid || lf.password.errors?.required)"
                      class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> This is required
                    </div>
                  </div>
                  <!-- <div class="form-group">
                      <select formControlName="client_id"  class="form-control">
                        <option *ngFor="let item of t_ids;" [ngValue]="item.key" placeholder="Location">
                          {{item.value}}
                        </option>
                        </select>
                        <div *ngIf="loginFormSubmitted && (lf.client_id.invalid || lf.client_id.errors?.required)"
                          class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Location is required
                        </div>
                  </div> -->
                  <div class="d-sm-flex justify-content-between mb-3 font-small-2">
                    <div class="remember-me mb-2 mb-sm-0">
                      <div class="checkbox auth-checkbox">
                        <input type="checkbox" formControlName="rememberMe" class="form-control" id="rememberMe">
                        <label for="rememberMe"><span class="font-small-2 mb-3 font-weight-normal">Remember Me</span></label>
                      </div>
                    </div>
                    <a [routerLink]="['/forgotpassword']">Forgot Password?</a>
                  </div>
                  <div class="d-flex justify-content-between flex-sm-row flex-column">
                    <a [routerLink]="['/register']" class="btn bg-light-primary mb-2 mb-sm-0">Register</a>
                    <a  class="btn btn-primary" (click)="onSubmit()">Login</a>
                  </div>
                </form>
                <hr *ngIf="show_sso()">
                <div class="d-flex justify-content-between align-items-center" *ngIf="show_sso()">

                  <h6 class="text-primary m-0">Or Single Sign On</h6>
                  <ngx-spinner></ngx-spinner>

                  <div class="login-options" *ngIf="show_sso() && !isConsoleUrl()">

                    <button type="button"  (click)="login()" class="btn btn-info btn-block btn-raised">Single Sign-On</button>
                    <!-- <a class="btn btn-sm btn-social-icon btn-facebook mr-1"><span class="fa fa-facebook"></span></a>
                    <a class="btn btn-sm btn-social-icon btn-twitter mr-1"><span class="fa fa-twitter"></span></a> -->
                  </div>
                  <div class="login-options" *ngIf="isConsoleUrl()">
                    <div class="dropdown btn-group dropup mr-1 mb-1" ngbDropdown container="body">
                      <button type="button" class="btn btn-info btn-raised dropdown-toggle" ngbDropdownToggle>
                          Location
                          <span class="caret"></span>
                      </button>
                      <div ngbDropdownMenu class="dropdown-menu" role="menu">
                          <a class="dropdown-item" href="https://gulfcoast.orthointouch.com">Gulf Coast</a>
                          <a class="dropdown-item" href="https://midatlantic.orthointouch.com">Mid Atlantic</a>
                          <a class="dropdown-item" href="https://mideast.orthointouch.com">Mid East</a>
                          <a class="dropdown-item" href="https://midwest.orthointouch.com">Mid West</a>
                          <a class="dropdown-item" href="https://mountainwest.orthointouch.com">Mountain West</a>
                          <a class="dropdown-item" href="https://northtexas.orthointouch.com">North Texas</a>
                          <a class="dropdown-item" href="https://southeast.orthointouch.com">South East</a>
                          <a class="dropdown-item" href="https://southtexas.orthointouch.com">South Texas</a>
                          <a class="dropdown-item" href="https://spillers.orthointouch.com">Spillers</a>
                      </div>
                  </div>
                    <!-- <a class="btn btn-sm btn-social-icon btn-facebook mr-1"><span class="fa fa-facebook"></span></a>
                    <a class="btn btn-sm btn-social-icon btn-twitter mr-1"><span class="fa fa-twitter"></span></a> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Login Page Ends-->
