import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from './layouts/full/full-layout.component';

import { Full_ROUTES } from './shared/routes/full-layout.routes';

import { AuthCallbackComponent } from './shared/adal/ad-auth-callback.component';

import { AuthGuard } from './shared/auth/auth-guard.service';

import { ConfirmationPageComponent } from './pages/full-pages/confirmation/confirmation-page.component';
import { RegisterComponent } from './orthointouch/account/register/register.component';
import { ForgotPasswordPageComponent } from './orthointouch/account/forgot-password/forgot-password-page.component';
import { ResetPasswordPageComponent } from './orthointouch/account/reset-password/reset-password.component';
import { LoginPageComponent } from './orthointouch/account/login/login-page.component';

const appRoutes: Routes = [
  {
    path: 'auth-callback', component: AuthCallbackComponent
  },
  {
    path: 'login', component: LoginPageComponent
  },
  {
    path: 'register', component: RegisterComponent
  },
  {
    path: 'forgotpassword', component: ForgotPasswordPageComponent
  },
  {
    path: 'reset-password', component: ResetPasswordPageComponent
  },
  {
    path: 'confirmation', component: ConfirmationPageComponent
  },
  {
    path: '', component: FullLayoutComponent,
    children: [
      ...Full_ROUTES,
      {
        path: '',
        redirectTo: 'notifications/appointments',
        pathMatch: 'full'
      }
    ],
    canActivateChild: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'pages/error'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
