import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  constructor(private angularFireMessaging: AngularFireMessaging,
    private _httpClient: HttpClient) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.onTokenReceived(token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
        return '';
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log('new message received. ', payload);
        this.currentMessage.next(payload);
      })
  }


  onTokenReceived(token) {

    const payload = {
      key: 'fcm_token',
      value: token,
    }

    this._httpClient.post(environment.apiUrl + '/api/admin/saveoitconfig', payload).toPromise()
    .then(response => {
      if (response === '') {
        // tslint:disable-next-line:no-string-throw
        throw 'Unable to set token in oitconfig';
      }
      console.log('FCM Token Set');
    }).catch(error => {
      console.error(error);
    });

    const topic = 'all';
    fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + topic, {
      method: 'POST',
      headers: new Headers({
        'Authorization': 'key=' + 'AAAA1W4Jgno:APA91bFrVYUWj58kj-8qVHo3xigVAvn9S7BmWL2MqyB_gleOX4nu22m1JTlS_gYEvfBtm77uxJECLzexiiorFZTpm6DLbE6qg4LwpaahSlEWWgBHyDIlGKV-VjJSI0RwBtaSWxfWgImG'
      })
    }).then(response => {
      if (response.status < 200 || response.status >= 400) {
        // tslint:disable-next-line:no-string-throw
        throw 'Error subscribing to topic: ' + response.status + ' - ' + response.text();
      }
      console.log('Subscribed to "' + topic + '"');
    }).catch(error => {
      console.error(error);
    });
  }
}
