import { RouteInfo } from './vertical-menu.metadata';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/full-layout',
    title: 'Home',
    icon: 'ft-layout',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: ['admin', 'director', 'editor', 'operator', 'user'],
    submenu: [
      {
        path: '/notifications/appointments',
        title: 'Appointment Summary',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director', 'editor', 'operator', 'user']
      },
      {
        path: '/notifications/sent',
        title: 'Sent Notifications',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director', 'editor', 'operator', 'user']
      },
      {
        path: '/notifications/received',
        title: 'Received Notifications',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director', 'editor', 'operator', 'user']
      }
    ]
  },
  {
    path: '/full-layout',
    title: 'Payment Support',
    icon: 'ft-layout',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: ['smiledoctors'],
    submenu: [
      {
        path: '/paymentsupport/achnotifications',
        title: 'ACH Notifications',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['smiledoctors']
      },
      {
        path: '/paymentsupport/declinenotifications',
        title: 'Decline Notifications',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['smiledoctors']
      },
      {
        path: '/paymentsupport/expiredccnotifications',
        title: 'Expired CC Notifications',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['smiledoctors']
      },
      {
        path: '/paymentsupport/paymentnotifications',
        title: 'Payment Notifications',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['smiledoctors']
      }
    ]
  },
  {
    path: '/templates',
    title: 'Templates',
    icon: 'ft-edit',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: ['admin', 'editor', 'director'],
    submenu: [
      {
        path: '/templates/text',
        title: 'Text Templates',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'editor', 'director']
      },
      {
        path: '/templates/email',
        title: 'Email Templates',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'editor', 'director']
      }
    ]
  },
  {
    path: '/tasks',
    title: 'Tasks',
    icon: 'ft-check-square',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['admin', 'editor', 'director', 'operator']
  },
  {
    path: '',
    title: 'Settings',
    icon: 'ft-sliders',
    class: 'has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    roles: ['admin', 'director', 'smiledoctors'],
    submenu: [
      {
        path: '/settings/reminder',
        title: 'Reminders',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director']
      },
      {
        path: '/settings/dayof',
        title: 'Day Of',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director']
      },
      {
        path: '/settings/missed',
        title: 'Missed',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director']
      },
      {
        path: '/settings/recall',
        title: 'Recalls',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director']
      },
      {
        path: '/settings/followup',
        title: 'Follow Up',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director']
      },
      {
        path: '/settings/birthday',
        title: 'Birthday',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director']
      },
      {
        path: '/settings/achnotifications',
        title: 'ACH Notifications',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['smiledoctors']
      },
      {
        path: '/settings/declinedpayments',
        title: 'Declined Payments',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['smiledoctors']
      },
      {
        path: '/settings/expiringcc',
        title: 'Expiring CC Notifications',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['smiledoctors']
      },
      {
        path: '/settings/paymentnotifications',
        title: 'Payment Reminders',
        icon: '',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['smiledoctors']
      },
      {
        path: '/settings/newappointment',
        title: 'New Appointments',
        icon: '',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['director']
      }
    ]
  },
  {
    path: '/chat',
    title: 'Chat',
    icon: 'ft-message-square',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['chat']
  },
  {
    path: '/sendgrid',
    title: 'Campaigns',
    icon: 'ft-mail',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: ['emailcampaign'],
    submenu: [
      {
        path: '/sendgrid/dashboard',
        title: 'Dashboard',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['emailcampaign']
      },
      {
        path: '/sendgrid/campaigns',
        title: 'Campaigns',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['emailcampaign']
      },
      {
        path: '/sendgrid/contacts',
        title: 'Contact Lists',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['emailcampaign']
      },
      {
        path: '/sendgrid/senders',
        title: 'Sender Profiles',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['emailcampaign']
      },
      {
        path: '/sendgrid/unsubscribe-groups',
        title: 'Unsubscribe Groups',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['emailcampaign']
      },
      {
        path: '/sendgrid/custom-fields',
        title: 'Custom Fields',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['emailcampaign']
      }
    ]
  },
  {
    path: '/patients',
    title: 'Patients',
    icon: 'ft-users',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['admin', 'director']
  },
  {
    path: '/emailblast',
    title: 'Email Blast',
    icon: 'ft-mail',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['superadmin', 'emailblasts']
  },

  // {
  //   path: '/pages/whyilike',
  //   title: 'Reviews',
  //   icon: 'ft-thumbs-up',
  //   class: '',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   submenu: [],
  //   roles: ['admin', 'director', 'editor', 'operator']
  // },
  {
    path: 'null',
    title: '',
    icon: '',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['admin', 'director', 'editor', 'operator']
  },
  {
    path: '/pages',
    title: 'Support',
    icon: 'ft-help-circle',
    class: 'has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    roles: ['admin', 'director', 'editor', 'operator'],
    submenu: [
      {
        path: '/pages/gallery',
        title: 'How-To Videos',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director', 'editor', 'operator']
      },
      // {
      //   path: '/pages/kb',
      //   title: 'Knowledge Base',
      //   icon: '',
      //   class: '',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false,
      //   submenu: [],
      //   roles: ['admin', 'director', 'editor', 'operator']
      // },
      {
        path: '/pages/faq',
        title: 'F.A.Q',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director', 'editor', 'operator']
      },
      {
        path: '/pages/request-support',
        title: 'Support',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director', 'editor', 'operator']
      }
    ]
  },
  {
    path: '',
    title: 'Admin',
    icon: 'ft-user',
    class: 'has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: ['superadmin', 'smiledoctors', 'admin', 'emailblasts'],
    submenu: [
      {
        path: '/membership/users',
        title: 'User Manager',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin']
      },
      {
        path: '/membership',
        title: 'Membership',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['superadmin']
      },
      {
        path: '/admintasks',
        title: 'Admin Tasks',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['superadmin']
      },
      {
        path: '/oitconfig',
        title: 'OIT Config',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['superadmin']
      },
      {
        path: '/sdcopytemplates',
        title: 'Copy Templates',
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['superadmin', 'smiledoctors']
      }
    ]
  }
];
