import { Injectable } from '@angular/core';
import { ROUTES } from './vertical-menu-routes.config';
import { Subject } from 'rxjs';
import { RouteInfo } from './vertical-menu.metadata';
import { User, UserProfile } from '../auth/user';
import { Config } from '../shared.config';

@Injectable({
  providedIn: 'root'
})
export class VerticalMenuService {
  private links = new Array<RouteInfo>();
  private isLoggedIn = new Subject<boolean>();

  constructor() {
  }

  getLinks() {
    if (this.links.length === 0) {
      // reload sidebar menu on browser refresh
      const user = JSON.parse(localStorage.getItem(Config.userProfile));
      this.updateNav(user);
    }
    return this.links;
  }

  getLoginStatus() {
    return this.isLoggedIn;
  }

  updateLoginStatus(status: boolean) {
    this.isLoggedIn.next(status);

    if (!status) {
      this.clearAllItems();
    }
  }

  updateNav(userProfile: UserProfile) {

    const routes: RouteInfo[] = ROUTES.filter(menuItem => menuItem);
    this.links = [];
    const userRoles = userProfile.roles.map(ur => ur.name.toLowerCase());
    routes.forEach((route) => {
      const showMenu = route.roles.some(r => userRoles.includes(r.toLowerCase()));
      if(showMenu) {
        let filteredRoute: any = route;
        filteredRoute.submenu = route.submenu.filter(sm =>
          sm.roles.some(smr => userRoles.includes(smr.toLowerCase()))
        )
        this.links.push(filteredRoute);
      }
    })
  }

  addItem(route: RouteInfo) {
    this.links.push(route);
  }

  removeItem(route: RouteInfo) {
    this.links.forEach((item, index) => {
      if (item.title === route.title) {
        this.links.splice(index, 1);
      }
    });
  }

  clearAllItems() {
    this.links.length = 0;
  }
}
