<div class="toast-style-default d-flex justify-content-between" >
    <div class="mt-1">
        <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title" class="custom-title">
            {{ title }}
        </div>
        <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite" class="custom-message"
            [class]="options.messageClass" [innerHTML]="message">
        </div>
        <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite" class="custom-message"
            [class]="options.messageClass" [attr.aria-label]="message">
            {{ message }}
        </div>
        <a *ngFor="let btn of btns" class="btn btn-light text-dark mt-2" (click)="action($event)">
            {{btn.title}}
        </a>
    </div>

    <div class="text-right">
        <!-- <a class="btn btn-sm button" (click)="action($event)">
       {{options.chen}}
      </a> -->
        <a *ngIf="options.closeButton" (click)="remove()" class="close-icon">
            &times;
        </a>
    </div>
</div>
