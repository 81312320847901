import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';

export const HROUTES: RouteInfo[] = [
{
  path: '/full-layout',
    title: 'Home',
    icon: 'ft-layout',
    class: 'dropdown nav-item has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: ['admin', 'director', 'editor', 'operator', 'user'],
    submenu: [
      {
        path: '/notifications/appointments',
        title: 'Appointment Summary',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director', 'editor', 'operator', 'user']
      },
      {
        path: '/notifications/sent',
        title: 'Sent Notifications',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director', 'editor', 'operator', 'user']
      },
      {
        path: '/notifications/received',
        title: 'Received Notifications',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director', 'editor', 'operator', 'user']
      }
    ]
  },
  {
    path: '/templates',
    title: 'Templates',
    icon: 'ft-edit',
    class: 'dropdown nav-item has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: ['admin', 'editor', 'director'],
    submenu: [
      {
        path: '/templates/text',
        title: 'Text Templates',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'editor', 'director']
      },
      {
        path: '/templates/email',
        title: 'Email Templates',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'editor', 'director']
      }
    ]
  },
  {
    path: '/tasks',
    title: 'Tasks',
    icon: 'ft-check-square',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['admin', 'editor', 'director', 'operator']
  },
  {
    path: '',
    title: 'Settings',
    icon: 'ft-sliders',
    class: 'dropdown nav-item has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    roles: ['admin', 'director'],
    submenu: [
      {
        path: '/settings/reminder',
        title: 'Reminders',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director']
      },
      {
        path: '/settings/dayof',
        title: 'Day Of',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director']
      },
      {
        path: '/settings/missed',
        title: 'Missed',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director']
      },
      {
        path: '/settings/recall',
        title: 'Recalls',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director']
      },
      {
        path: '/settings/followup',
        title: 'Follow Up',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director']
      },
      {
        path: '/settings/birthday',
        title: 'Birthday',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director']
      },
      {
        path: '/settings/declinedpayments',
        title: 'Declined Payments',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['smiledoctors']
      },
      {
        path: '/settings/achnotifications',
        title: 'ACH Notifications',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['smiledoctors']
      },
      {
        path: '/settings/newappointment',
        title: 'New Appointment',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director']
      },
      {
        path: '/settings/paymentnotifications',
        title: 'Payment Reminders',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['smiledoctors']
      },
      {
        path: '/settings/expiringcc',
        title: 'Expiring CC Notifications',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['smiledoctors']
      }


    ]
  },
  {
    path: '/chat',
    title: 'Chat',
    icon: 'ft-message-square',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['chat']
  },
  // {
  //   path: '/pages/whyilike',
  //   title: 'Reviews',
  //   icon: 'ft-thumbs-up',
  //   class: 'dropdown-item',
  //   badge: '',
  //   badgeClass: '',
  //   isExternalLink: false,
  //   submenu: [],
  //   roles: ['admin', 'director', 'editor', 'operator']
  // },
  {
    path: 'null',
    title: '',
    icon: '',
    class: '',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    submenu: [],
    roles: ['admin', 'director', 'editor', 'operator']
  },
  {
    path: '/pages',
    title: 'Support',
    icon: 'ft-help-circle',
    class: 'dropdown nav-item has-sub',
    badge: '',
    badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1',
    isExternalLink: false,
    roles: ['admin', 'director', 'editor', 'operator', 'user'],
    submenu: [
      {
        path: '/pages/gallery',
        title: 'How-To Videos',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director', 'editor', 'operator', 'user']
      },
      // {
      //   path: '/pages/kb',
      //   title: 'Knowledge Base',
      //   icon: 'ft-arrow-right submenu-icon',
      //   class: 'dropdown-item',
      //   badge: '',
      //   badgeClass: '',
      //   isExternalLink: false,
      //   submenu: [],
      //   roles: ['admin', 'director', 'editor', 'operator']
      // },
      {
        path: '/pages/faq',
        title: 'F.A.Q',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['admin', 'director', 'editor', 'operator', 'user']
      }
    ]
  },
  {
    path: '',
    title: 'Admin',
    icon: 'ft-user',
    class: 'dropdown nav-item has-sub',
    badge: '',
    badgeClass: '',
    isExternalLink: false,
    roles: ['superadmin'],
    submenu: [
      {
        path: '/membership',
        title: 'Membership',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['superadmin']
      },
      {
        path: '/admintasks',
        title: 'Admin Tasks',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['superadmin']
      },
      {
        path: '/oitconfig',
        title: 'OIT Config',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['superadmin']
      },
      {
        path: '/sdcopytemplates',
        title: 'Copy Templates',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['superadmin', 'smiledoctors']
      },
      {
        path: '/csvemailblasts',
        title: 'Email Campaigns (CSV)',
        icon: 'ft-arrow-right submenu-icon',
        class: 'dropdown-item',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [],
        roles: ['superadmin', 'emailblasts']
      }
    ]
  }
];
