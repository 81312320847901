import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators, NgForm, FormBuilder } from '@angular/forms';
import { MovingDirection, WizardComponent } from 'angular-archwizard';
import { RegisterService } from './register.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { UserProfile, User } from 'app/shared/auth/user';
import { AuthService } from 'app/shared/auth/auth.service';
import { Router } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Config } from 'app/shared/shared.config';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  providers: [RegisterService],
})
export class RegisterComponent implements OnInit {

  @ViewChild(WizardComponent)
  public wizard: WizardComponent;
  navbarLocation = 'left';

  private hubConnection: signalR.HubConnection;

  public isLoading = false;

  public emailVerificationCode;
  public validKey = false;
  public disableNext = false;
  public validateEmailShow = true;
  public isEmailVerified = false;
  public isEmailCodeSent = false;
  public topsConnectionSucceed = false;
  public cloud9Show = false;
  public topsOrthoShow = false;
  public installTimeShow = false;
  public errorMessage = '';
  public numbersByCode = [];
  // public pmsValues = ['topsOrtho', 'OrthoTrac', 'Cloud9Ortho', 'Dolphin', 'SmileDoctors'];
  public pmsValues = ['topsOrtho', 'OrthoTrac', 'Cloud9Ortho', 'SmileDoctors'];
  // public pms = '';
  registrationForm: FormGroup;
  userForm: FormGroup;
  phoneForm: FormGroup;


  constructor(
    private _registerService: RegisterService,
    public _toastr: ToastrService,
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) {
    if (window.innerWidth < 768) {
      this.navbarLocation = 'top';
    }
  }

  ngOnInit() {
    // Single Parent Form contains all child forms
    this.registrationForm = this.fb.group({
      subcriptionKeyForm: this.fb.group({
        subcription_key: new FormControl('', [Validators.required])
      }, ),
      userForm: this.fb.group({
        practice_Name: new FormControl('', [Validators.required]), // , Validators.pattern('^[a-zA-Z ]*$')]),
        UserName: new FormControl('', Validators.required),
        email: new FormControl('', [Validators.required, Validators.email]),
        phone: new FormControl('', [Validators.required, Validators.pattern(/^(?:\+1|0)?[2-9]\d{2}[2-9](?!11)\d{6}$|^(?:\+353|0)?\s*(?:1|(?:21|22|23|24|25|26|27|28|29)|3(?:0|1)|4(?:2|3|4|5|6|7)|5(?:2|4|6|7)|6(?:4|6|9)|7(?:1|2|4|5|6|7|9)|9(?:1|3|4|5|6|7|9))\s*(?:\d[\s-]*){7}$|^(?:\+61|0)?[2-57-9]\d{3}\s?\d{3}\s?\d{3}$/)]),
        time_Zone: new FormControl('', Validators.required),
        Password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/)]),
        ConfirmPassword: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/)])
      }, { validator: this.MustMatch('Password', 'ConfirmPassword') } ),
      phoneForm: this.fb.group({
        // areaCode: new FormControl('512', Validators.required),
        // sms_phone_number: new FormControl('', Validators.required),
        pms: new FormControl('')
      }),
      topsOrthoForm: this.fb.group({
        topsIP: new FormControl('', Validators.required),
        topsPort: new FormControl('', Validators.required),
        topsPwd: new FormControl('', Validators.required),
        confirmtopsPwd: new FormControl('', Validators.required),
      }, { validator: this.MustMatch('topsPwd', 'confirmtopsPwd') } ),
      dolphinForm: this.fb.group({
        dolphin_Custom_Connection: new FormControl(true),
        dolphin_Custom_Connection_String: new FormControl('', Validators.required),
        dolphin_DataSource: new FormControl('', Validators.required),
        dolphin_DB: new FormControl('', Validators.required),
        dolphin_Windows_Auth: new FormControl(true, Validators.required),
        dolphin_DB_User_ID: new FormControl('', Validators.required),
        dolphin_DB_Password: new FormControl('', Validators.required),
        dolphin_DB_Confirm_Password: new FormControl('', Validators.required),
      }, { validator: this.MustMatch('dolphin_DB_Password', 'dolphin_DB_Confirm_Password') } ),
      cloud9Form: this.fb.group({
        cloud9_API_URI: new FormControl('', Validators.required),
        cloud9_ClientID: new FormControl('', Validators.required),
        cloud9_UserName: new FormControl('', Validators.required),
        cloud9_Password: new FormControl('', Validators.required),
        cloud9_Confirm_Password: new FormControl('', Validators.required)
      }, { validator: this.MustMatch('cloud9_Password', 'cloud9_Confirm_Password') } ),
      settingsForm: this.fb.group({
        practice_location_name: new FormControl('[OfficeLocationName]'),
        contact_name: new FormControl(''),
        address: new FormControl('[OfficeLocationAddress]'),
        city: new FormControl('[OfficeLocationCity]'),
        state: new FormControl('[OfficeLocationState]'),
        zip: new FormControl('[OfficeLocationPostalCode]'),
        facebook_url: new FormControl(''),
        twitter_url: new FormControl(''),
        youtube_url: new FormControl(''),
        instagram_url: new FormControl(''),
        google_plus_url: new FormControl(''),
        yelp_url: new FormControl(''),
        template_reminder: new FormControl(true),
        template_missed: new FormControl(true),
        template_recall: new FormControl(true),
        template_followup: new FormControl(true),
        template_birthday: new FormControl(true),
        template_retainer: new FormControl(true)
      })
    });

    this.setupSignalR();
  }


  setUserNameValue(event) {
    this.uf.UserName.setValue(event.replace(/[^a-zA-Z]/g, '').toLowerCase());
  }

  validateSubKey() {
    const str = this.sk.subcription_key.value;
    if (str.endsWith('1011')) {
      this.validKey = true;
      this.wizard.currentStep.canEnter = false;
      this.wizard.goToNextStep();
    }
  }
  get sk() {
    return (this.registrationForm.get('subcriptionKeyForm') as FormGroup).controls;
  }
  // Get user form controls for easier to use
  get uf() {
    return (this.registrationForm.get('userForm') as FormGroup).controls;
  }

  // Get phone form controls for easier to use
  get pf() {
    return (this.registrationForm.get('phoneForm') as FormGroup).controls;
  }

  get tf() {
    return (this.registrationForm.get('topsOrthoForm') as FormGroup).controls;
  }

  get df() {
    return (this.registrationForm.get('dolphinForm') as FormGroup).controls;
  }

  get cf() {
    return (this.registrationForm.get('cloud9Form') as FormGroup).controls;
  }

  get sf() {
    return (this.registrationForm.get('settingsForm') as FormGroup).controls;
  }

  setPMS(pmsval) {
    this.pf.pms.setValue(pmsval);
    this.wizard.goToNextStep();
  }
  // Password match checks
  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({mustMatch: true});
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  // Dolphin custom connection checkbox change
  dConnChange(isChecked: boolean) {
    if (isChecked) {
      this.df.dolphin_Custom_Connection_String.setValidators(Validators.required);
    } else {
      this.df.dolphin_Custom_Connection_String.clearValidators();
    }
    this.df.dolphin_Custom_Connection_String.updateValueAndValidity();
  }

  // Dolphin windows auth checkbox change
  dWinAuthChange(isChecked: boolean) {
    if (isChecked) {
      this.df.dolphin_DB_User_ID.setValidators(Validators.required);
      this.df.dolphin_DB_Password.setValidators(Validators.required);
      this.df.dolphin_DB_Confirm_Password.setValidators(Validators.required);
    } else {
      this.df.dolphin_DB_User_ID.clearValidators();
      this.df.dolphin_DB_Password.clearValidators();
      this.df.dolphin_DB_Confirm_Password.clearValidators();
    }
    this.df.dolphin_DB_User_ID.updateValueAndValidity();
    this.df.dolphin_DB_Password.updateValueAndValidity();
    this.df.dolphin_DB_Confirm_Password.updateValueAndValidity();
  }

  public setupSignalR() {

    this.hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(environment.apiUrl + '/progressHub') // + '?group=' + this._auth.getCurrentUser().userName)
    .configureLogging(signalR.LogLevel.Information)
    .withAutomaticReconnect()
    .build();

    Object.defineProperty(WebSocket, 'OPEN', { value: 1, });
    this.hubConnection
      .start()
      .then(() => {
        console.log('Connection started!!!');
        this.sendSignalRMessage(); // Sends message to Server that connection was made
      })
      .catch(err => console.log('Error while starting connection: ' + err));

    // On Progress Message is called when Hub sends message to Client from Server
    this.hubConnection.on('onProgressMessage', (message) => {
      // console.log(clientID);
      this.processIncommingProgressMessage(message);
    });

  }

  // Moves User to step 2 after database has been created
  processIncommingProgressMessage (message) {
    // $('#signalTest').append('Data: ' + data);
    if (message) {
      const done = message.includes('Complete');
      if (done) {
        console.log(message);
        this.isEmailVerified = true;
        this.isLoading = false;
        // Go to the next step 2 of the Wizard

        // 2/25/2022

        // Need to update so that the database is created first - then user can authenticate

        // this.wizard.goToNextStep();
        // this.authService
        //   .signinUser(this.uf.email.value, this.uf.Password.value) //, this.uf.UserName.value)
        //   .subscribe(
        //     items => {
        //       if (items.status === 'success') {
        //         this._toastr.success(items.result, 'Success!' , {closeButton : true});
        //         this.authService.loadSecurityContext()
        //           .subscribe((response: any) => {
        //             const userProfile = response[Config.userProfile] as UserProfile;

        //             this.authService.setCurrentUser(userProfile);
        //             localStorage.setItem(Config.userProfile, JSON.stringify(userProfile));

        //             // this.sideBarService.updateNav(userProfile);
        //             // this.sideBarService.updateLoginStatus(true);

        //             // this.router.navigate(['notifications/appointments']);
        //         });
        //       } else {
        //           this._toastr.error(items.result, 'Error!', {closeButton : true});
        //       }
        //     },
        //     error => {
        //       this._toastr.error(
        //         'Incorrect username or password.',
        //         'Login Failed!',
        //         { timeOut: 2000 }
        //       );
        //       // this.loginError = 'Incorrect username or password';
        //       // console.log(error);
        //     }
        // );

      }
        // toaster handled in navbar so all of site can get the notification (2 Min Timeout)
        this._toastr.success(message, 'Account Creation Status:',  { 'timeOut': 120000 });
    }
  }

    // send handshake to server, uncomment to get test reply
    public sendSignalRMessage(): void {
      // this.hubConnection.invoke('OnMessageFromClient', 'angular') // Here we are calling a Method OnMessageFromClient on the HUB class
      //   .catch((error: any) => {
      //       console.log('sendSignalRMessage error -> ' + error);
      //     });
  }

  // Step 1 - Prevent retuning to step 1
  public enterFirstStep(navigationDirection) {

    // For step 2 testing == REMOVE
    // this.wizard.navigation.goToNextStep();

    if (navigationDirection === MovingDirection.Backwards) {
      this.wizard.goToNextStep();
    }
  }


  // Step 1a - Send Validate Email
  public sendValidationCode() {

    // API call to Send Verification Email
    this.errorMessage = '';
    this._registerService.sendregistrationcode(this.uf.email.value, this.uf.UserName.value, this.uf.email.value)
    .subscribe(
      retVal => {
        this.isEmailCodeSent = true;
      },
      error => {
        this.isLoading = false;
        this._toastr.error(
          error.error,
          'Verification Failed!',
          { timeOut: 2000 }
        );
        console.log(error);
        this.isEmailVerified = false;
      },
      () => {
        const x = 1;
      }
    );
  }

  public resendValidationCode(email) {
      this._registerService.sendregistrationcode(email,
      this.uf.UserName.value,
      this.uf.email.value);
  }

  // Step 1-b - Verify Registration Code
  validateEmailCode() {
    this.isEmailVerified = false;
    this.isLoading = true;
    this.disableNext = true;
    // tslint:disable-next-line:max-line-length
    this._registerService.verifyregistrationcode(this.uf.practice_Name.value, this.uf.UserName.value, this.uf.email.value, this.emailVerificationCode, this.uf.Password.value)
      .subscribe(
        retVal => {
          if (typeof retVal === 'number') {
            this.isLoading = false;
            this.wizard.goToNextStep();
            return true;
          } else {
            this.isLoading = false;
            this._toastr.error(
              'Unable to verify user with specified code.',
              'Verification Failed!',
              { timeOut: 2000 }
            );
            // this.isEmailVerified = false;
            this.disableNext = false;
            return false;
          }
        },
        error => {
          this.isLoading = false;
          this._toastr.error(
            'Unable to verify user with specified code.',
            'Verification Failed!',
            { timeOut: 2000 }
          );
          this.disableNext = false;
          // this.loginError = 'Incorrect username or password';
          console.log(error);
          this.isEmailVerified = false;
        }
      );
      // this.emailVerificationCode = '';
  }

  // Step 2 : Show Practice Management System Options
  showPMSForm(pms) {

    this.cloud9Show = false;
    this.topsOrthoShow = false;
    this.installTimeShow = false;

    if (pms === 'cloud9' || pms === 'smiledoctors') {
      this.cloud9Show = true;
    }
    if (pms === 'topsortho') {
      this.topsOrthoShow = true;

    }
    if (pms === 'dophin' || pms === 'orthotrac') {
      this.installTimeShow = true;
    }
  }

  // Step 2 : Save PMS Information
  public pmsForm_Next(pmsForm: NgForm) {


    // Verify Tenant Database
    const userID = localStorage.getItem('userID');


    let dbCreated;
    if (userID) {
      this._registerService.verifyTenantDB(userID)
        .subscribe(
          retVal => {
            dbCreated = retVal;
            if (retVal === 0) {
              this._toastr.error(
                'Please wait another 30 seconds.  Your database is still being created.',
                'Database Not Ready',
                { timeOut: 5000 }
              );
            } else {
              // Continue if DB Exists
              if (dbCreated > 0) {

                // topsOrtho
                if (pmsForm.value.rb_pms === 'topsOrtho') {

                  // tslint:disable-next-line:max-line-length
                  const connectionSucceed = this._registerService.savetopsOrthoPMS(pmsForm.value.topsIP, pmsForm.value.topsPort, pmsForm.value.topsPwd)
                    .subscribe(
                      retval => {
                        if (retval !== '') {
                          this.wizard.goToNextStep();
                        }
                      },
                      error => {
                        this._toastr.error(
                          error.error.message,
                          'topsOrtho Server Verification Failed!',
                          { timeOut: 2000 }
                        );
                      }
                    );
                }

                // cloud9 & Smile Doctors
                if (pmsForm.value.rb_pms === 'Cloud9' || pmsForm.value.rb_pms === 'SmileDoctors') {
                  let isSD = '0';
                  if (pmsForm.value.rb_pms === 'SmileDoctors') {
                    isSD = '1';
                  }
                  // tslint:disable-next-line:max-line-length
                  const connectionSucceed = this._registerService.savecloud9PMS(userID, pmsForm.value.cloud9_clientID, pmsForm.value.cloud9_api_uri, isSD)
                    .subscribe(
                      retval => {
                        if (retval !== '') {
                          this.wizard.goToNextStep();
                        }
                      },
                      error => {
                        this._toastr.error(
                          error.error.message,
                          'Cloud9 Server Verification Failed!',
                          { timeOut: 2000 }
                        );
                      }
                    );
                }
              }


            }
          },
          error => {
            this._toastr.error(
              error.error.message,
              'An error has occured',
              { timeOut: 2000 }
            );
          }
        );
    }
  }

  // Validates topsOrtho server inputs, displays success / failure
  testTopsConnection() {

    if (this.pf.pms.value === 'topsOrtho') {

      // Make call to API to validate PMS System
      this.topsConnectionSucceed = false;
      this._registerService.testopsOrthoPMS(this.tf.topsIP.value, this.tf.topsPort.value, this.tf.topsPwd.value)
        .subscribe(
          retval => {
            if (retval) {
              this.topsConnectionSucceed = true;
              this._toastr.success(
                'topsOrtho Server Verification Succeeded!', // retval.toString(),
                'Success',
                { timeOut: 2000 }
              );
            } else {
              this.topsConnectionSucceed = false;
              this._toastr.error(
                'Unable to Connect',
                'topsOrtho Server Credential Verification Failed!',
                { timeOut: 2000 }
              );
            }
          },
          error => {
            this._toastr.error(
              error.error.message,
              'topsOrtho Server Verification Failed!',
              { timeOut: 2000 }
            );
          }
        );
    }
  }

    // Last Step  - set contact name
    public enterLastStep(navigationDirection) {

      this.sf.contact_name.setValue(this.uf.practice_Name.value);
    }

    // Last Step  - set contact name
    public enterPMSDetails(navigationDirection) {

      if (navigationDirection === MovingDirection.Forwards) {

        if (this.pf.pms.value  !== 'topsOrtho') {
          this.wizard.goToNextStep();
        }
      } else {
        if (this.pf.pms.value  !== 'topsOrtho') {
          this.wizard.goToPreviousStep();
        }
      }
    }
  // Step 3: NotificationOptionsNext(optionsForm)
  NotificationOptionsNext(optionsForm: NgForm) {

    // Get Form details
    const emailName = optionsForm.value.emailName;
    const replyTo = optionsForm.value.replyTo;
    const facebookUrl = optionsForm.value.facebookUrl;
    const instagramUrl = optionsForm.value.instagramUrl;
    const twitterUrl = optionsForm.value.twitterUrl;
    const youtubeUrl = optionsForm.value.youtubeUrl;
    const googleplusUrl = optionsForm.value.googleplusUrl;
    const yelpUrl = optionsForm.value.yelpUrl;
    const enablesettings = optionsForm.value.rb_enable;

    console.log(emailName);
    console.log(replyTo);
    console.log(facebookUrl);
    console.log(enablesettings);
    console.log(instagramUrl);

    // const userID = localStorage.getItem('userID');

    // tslint:disable-next-line:max-line-length
    const createAccountSucceed = this._registerService.createTenant(emailName, replyTo, facebookUrl, instagramUrl, youtubeUrl, twitterUrl, googleplusUrl, yelpUrl, enablesettings)
      .subscribe(
        retval => {
          this._toastr.success(
            'Your OrthoInTouch account has been created!', // retval.toString(),
            'Success',
            { timeOut: 2000 }
          );

          this.router.navigate(['notifications/appointments']);
        },
        error => {
          this._toastr.error(
            error.error.message,
            'Account Creation Error!',
            { timeOut: 2000 }
          );
        }
      );
  }

  configureAccount() {
    console.log(this.registrationForm.value);
    const data = {
      ...this.registrationForm.get('userForm').value,
      ...this.registrationForm.get('phoneForm').value,
      ...this.registrationForm.get('topsOrthoForm').value,
      ...this.registrationForm.get('dolphinForm').value,
      ...this.registrationForm.get('cloud9Form').value,
      ...this.registrationForm.get('settingsForm').value
    }
    console.log(data);

    this._registerService.configureTenant(data).subscribe(
      (res) => {
        this._toastr.success(
          'Logging into your OrthoInTouch account.', // retval.toString(),
          'Welcome',
          { timeOut: 2000 }
        );
        this.router.navigate(['notifications/appointments']);
        console.log(res);
      }, (err) => {
        console.log(err);
      }
    )
  }
}