import { Component, ViewChild, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ResetPasswordModel } from './reset-password.model';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordPageComponent implements OnInit {
    @ViewChild('f') forogtPasswordForm: NgForm;

    email = '';
    userId = '';
    code = '';
    constructor(private router: Router,
        private route: ActivatedRoute,
        private _httpClient: HttpClient,
        public _toastr: ToastrService) { }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            // Defaults to 0 if no query param provided.
            this.userId = params['email'] || '';
            this.code = params['code'] || '';
          });

        if (!this.code) {
            this.router.navigate(['login'], { relativeTo: this.route.parent });
        }
    }

    // On submit click, reset form fields
    onSubmit() {
        const modelP = {
            Email: this.forogtPasswordForm.controls.inputUsername.value,
            Password: this.forogtPasswordForm.controls.inputPassword.value,
            ConfirmPassword: this.forogtPasswordForm.controls.inputConfirmPassword.value,
            Code: encodeURI(this.code)
        }

        this._httpClient.post(environment.apiUrl + '/api/Account/ResetPassword', modelP)
        .subscribe(
            response => {
                this.onSuccess();
            },
            error => {
                this.onFailure(error.error.message);
            },
            () => {
                // this.forogtPasswordForm.reset();
            }
        );
    }

    onSuccess() {
        this._toastr.success('Password Reset Successfully', 'Success!',  { 'timeOut': 2000 });
        this.onLogin();
    }
    onFailure(msg) {
        if (msg) {
            this._toastr.error('There was a problem resetting your password.  ' + msg, 'Failure!',  { 'timeOut': 2000 });
        } else {
            this._toastr.error('There was a problem resetting your password.  Please contact support.', 'Failure!',  { 'timeOut': 2000 });
        }
    }

    // On login link click
    onLogin() {
        this.router.navigate(['login'], { relativeTo: this.route.parent });
    }

    // On registration link click
    onRegister() {
        this.router.navigate(['register'], { relativeTo: this.route.parent });
    }
}
